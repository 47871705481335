.select-box-container {
  display: flex;
  flex-direction: column !important;
  margin: 0 !important;
  width: 100%;
  gap: 15px;
  //zmiany
  .custom-input {
    //font
    font-family: 'Open Sans', sans-serif;
    display: flex;
    position: relative;
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    height: 30px;
    cursor: pointer;
    padding: 0 7px;
    &.error {
      border: 1px solid $danger;
    }
    .arrow-down {
      top: 44%;
      border-color: #343a40 transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0;
      width: 0;
      height: 0;
      left: 95%;
      position: absolute;
    }
    span {
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      color: $black !important;
      vertical-align: middle;
      font-weight: 400;
      margin: 0;
      line-height: 102%;
      display: flex;
      align-items: center;
      strong {
        color: $black;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 700;
        line-height: 102%;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        margin-left: 3px;
      }
      &.info {
        font-style: italic;
      }
    }
    .list {
      position: absolute;
      left: 0;
      right: 0;
      top: 27px;
      display: none;
      flex-direction: column;
      background: #ffffff;
      overflow-y: auto;
      max-height: 220px;
      border-radius: 5px;
      border: 0.7px solid #fff;
      box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
      padding: 5px;
      .list-option {
        display: flex;
        align-items: center;
        width: 100%;
        color: #1d1d1d;
        font-weight: 300;
        padding: 10px 15px;
        font-family: 'Open Sans', sans-serif;
        border-radius: 5px;
        &:hover {
          background: #f5f5f5 !important;
        }
        .name {
          text-transform: uppercase;
          color: $black;
          font-weight: 400;
          margin-top: 0 !important;
        }
        .surname {
          font-weight: 700;
          text-transform: uppercase;
          color: $black;
          margin-bottom: 0 !important;
          margin-left: 5px;
        }
      }
      &.active {
        display: flex;
      }
    }
  }
  //end
  .select-team {
    background: #ffffff;
    border-radius: 3px;
    width: 309px;
    height: 25px;
    border: none;
    padding-left: 26px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 106%;
    text-transform: uppercase;
    color: $black;
  }
  .select-team-image {
    position: absolute;
    height: 12px;
    width: 12px;
    top: 11px;
    left: 13px;
  }
}
