.serchbar {
  margin-left: 20px;
  label {
    display: flex;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 0;
    height: 25px;
    color: #000000;
    span {
      margin-right: 15px;
      margin-bottom: 0;
    }
    img {
      width: auto;
      max-width: 180px;
      color: #495057;
      margin-left: 0.5rem;
    }
  }
}
