.remove-image-popup {
  justify-content: center;
  align-items: center;
  height: 576px;
  .popup__wrapper {
    background: rgba(0, 0, 0, 0.6);
  }

  .popup__text {
    font-size: 20px;
    color: #fff;
    &.extended {
      font-size: 48px;
    }
  }
}
