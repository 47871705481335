.popup {
  position: fixed;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 20px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
  &--positive {
    border: 8px solid rgba(85, 166, 48, 0.5);
  }
  &--neutral {
    outline: 10px solid rgba(255, 214, 0, 0.3);
  }
  &--negative {
    outline: 10px solid rgba(206, 90, 105, 0.3);
  }
  &--default {
    border-color: #ffffff;
    outline: 10px solid rgba(255, 255, 255, 0.3);
  }
  &__wrapper {
    width: 100%;
    height: 100%;
    min-height: 350px;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: rgba(255, 255, 255, 0.8);
    &--redirect {
      width: 507px;
      height: 573px;
      a {
        color: $black;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        text-decoration: none;
        margin-top: 10px;
      }
    }
  }

  &__icon {
    width: 130px;
    height: 130px;
    margin: 20px auto 40px auto;
    figure,
    img {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }

  &__text {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    color: rgba(94, 94, 94, 1);
    text-align: center;
    opacity: 1;
    display: inline-block;
    &--positive {
      color: rgba(85, 166, 48, 1);
    }
    &--negative {
      color: #ce5a69;
    }
    &--neutral {
      color: #ffd600;
    }
    &--default {
      color: #ffffff;
    }

    &.extended {
      font-weight: 900;
      font-size: 20px;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
  }

  &__button {
    width: 207px;
    height: 26px;
    min-height: 26px;
    border-radius: 5px;
    font-size: 14px;
    border: 0;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-out;
    margin-right: 10px;
    &--positive {
      background: $main-green !important;
    }
    &--neutral {
      background: #ffd600 !important;
    }
    &--negative {
      background: #ce5a69 !important;
    }
  }

  &__error {
    color: red;
    font-size: 12px;
  }

  &__action-info {
    color: rgba(0, 0, 0, 0.4);
    font-size: 7px;
    text-align: center;
    display: block;
    max-width: 300px;
    margin: 15px auto 0 auto;
    strong {
      text-transform: uppercase;
      &:last-child {
        text-transform: lowercase;
      }
    }
    &.clickable {
      cursor: pointer;
    }
  }
}
