.external-link {
  width: 100%;
  padding: 5px 0;
  .input-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    .bottom-part-link {
      display: flex;
      margin-top: 5px;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
    }
    span {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: $black;
      display: block;
      white-space: nowrap;
      text-transform: capitalize;
      margin-bottom: 0;
    }
    input {
      max-width: 100%;
      background: #fff;
      border-radius: 8px;
      border: 1px solid $border-grey;
      height: 30px;
      padding: 5px 10px;
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      color: $black;
      flex-grow: 1;
      &.not-validated {
        border: 1px solid $danger;
      }
      &:active {
        border: 1px solid $main-green;
      }
      &:focus {
        border: 1px solid $main-green;
      }
    }
    button {
      background: #f0f0f0;
      border: 1px solid rgba(135, 193, 108, 0.3);
      border-radius: 5px;
      padding: 4px 12px;
      max-width: 100%;
      font-size: 12px;
      display: flex;
      align-items: center;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      justify-content: center;
      transition: 0.2s ease-out;
      &:hover {
        background-color: $main-green;
      }
      &.copy {
        background-color: transparent;
        text-transform: capitalize;
        width: 30px;
        height: 30px;
        border-radius: 8px;
        margin-left: 7px;
        padding: 0 !important;
        color: #fff;
        border: none;
        outline: none;
        position: relative;
        &:hover {
          background: $border-grey;
        }
        .tooltip {
          position: absolute !important;
          top: -39px !important;
          left: -13px !important;
          width: auto !important;
          background-color: rgba(0, 0, 0, 0.8) !important;
          font-size: 13px !important;
          border-radius: 5px !important;
          color: white !important;
          padding: 5px 10px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          text-align: center !important;
          opacity: 1 !important;
          &::after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid rgba(0, 0, 0, 0.75);
          }
        }
      }
      &.open {
        background-color: #55a630;
        text-transform: capitalize;
        color: #fff;
        border: none;
        outline: none;
      }
      &.save {
        background-color: $main-green;
        text-transform: capitalize;
        font-weight: 600;
        height: 30px;
        border-radius: 0 8px 8px 0;
        width: 50px;
        color: #fff;
        border: none;
        outline: none;
      }
    }
  }

  .button-container {
    display: flex;
    align-items: center;
    button {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
