.client-service {
  // display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 8px 20px;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 0 !important;
  }
  .box {
    width: 405px;
    height: 120px;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e7eaec;
    transition: box-shadow 300ms ease-in-out;
    padding: 0 !important;
    a {
      text-decoration: none;
      width: 100%;
    }
    .box-wrapper {
      display: flex;
      text-decoration: none;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        font-size: 40px;
        color: $icon;
        transition:
          font-size 300ms ease-in-out,
          color 300ms ease-in-out;
      }
    }
    .text-section {
      width: calc(100% - 120px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-right: 40px;
      h2 {
        color: $black;
        font-size: 18px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        margin: 0 !important;
      }
      span {
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        color: $black;
        margin: 15px 0 0 0 !important;
      }
    }
    &:hover {
      border: 1px solid #e7eaec;
      -webkit-box-shadow: 0 3px 7px 0 #a8a8a8;
      -moz-box-shadow: 0 3px 7px 0 #a8a8a8;
      box-shadow: 0 3px 7px 0 #a8a8a8 !important;
      .icon {
        font-size: 43px;
        color: $main-green;
      }
    }
    &:active {
      box-shadow: none !important;
    }
  }
  .white-break {
    background: rgba(255, 255, 255, 0.35);
    border-radius: 5px;
    border-radius: 5px;
    width: 100%;
    // max-width: 1517px;
    height: 100px;
    margin-bottom: 32px;
  }
}

// @media (max-width: 1747px) {
//   .client-service {
//     .box {
//       &:nth-child(3n + 3) {
//         margin-right: 26px;
//       }
//     }
//   }
// }
