nav {
  background-color: #3d3d3d;
  height: 100%;
  .avatar-section {
    height: 80px;
    margin-bottom: 30px;
    background-image: url('../assets/client-profile-bgi.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    // justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 12px;
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.99);
      box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.99);
      margin-right: 17px;
    }
    .name {
      display: flex;
      flex-direction: column;
      line-height: 9px;
      span {
        font-size: 8px;
        font-family: 'Open Sans', sans-serif;
        color: #ffffff;
        font-weight: 400;

        &:nth-child(2) {
          font-weight: 700;
          font-size: 12px;
        }
      }
    }
  }
  .menu-elements {
    li {
      list-style: none;

      a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        text-decoration: none;
        font-family: 'Open Sans', sans-serif;
        color: #ffffff;
        font-size: 12px;
        padding: 12px 0;
        padding-left: 17px;
        -webkit-transition: 0.2s ease-out;
        -o-transition: 0.2s ease-out;
        transition: 0.2s ease-out;
        &::before {
          content: '';
          width: 12px;
          height: 12px;
          display: block;
          background-color: #d9d9d9;
          border-radius: 50%;
          margin-right: 12px;
          -webkit-transition: 0.2s ease-out;
          -o-transition: 0.2s ease-out;
          transition: 0.2s ease-out;
        }
        &::after {
          position: absolute;
          left: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          content: '';
          display: block;
          width: 23px;
          height: 12px;
          background: rgba(255, 255, 255, 0.05);
          -webkit-transition: 0.2s ease-out;
          -o-transition: 0.2s ease-out;
          transition: 0.2s ease-out;
        }
      }
      &.sidebar-active {
        a {
          font-weight: 700;
          &::before {
            background-color: #4c8167;
          }
          &::after {
            background: rgba(76, 129, 103, 0.3);
          }
        }
      }
      &:hover {
        a {
          font-weight: 700;
        }
      }
    }
  }
}
