.correction-inputs-fields {
  padding: 28px;
  position: fixed;
  top: 34px;
  left: 50%;
  z-index: 11;
  transform: translateX(-50%);
  width: 701px;
  max-width: 100%;
  height: 87vh;
  max-height: 981px;
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 0px 70px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  .banner {
    margin-bottom: 0 !important;
  }
  .input-box {
    border: 0.5px solid rgba(206, 90, 105, 0.3) !important;
    border-radius: 5px;
    padding: 10px 30px;
    margin-bottom: 22px;
    position: relative;
    width: 80%;
    margin-left: auto;
    a {
      text-decoration: none;
      pointer-events: none;
      border: 0;
      background-color: transparent;
      pointer-events: none;
      color: $black;
      font-family: 'Open Sans', sans-serif;
      font-size: 13px;
    }
    .download-products {
      display: none;
    }
    label {
      color: $black;
      font-family: 'Open Sans', sans-serif;

      font-size: 13px;
      display: flex;
      .name {
        font-weight: 700;
        display: block;
        width: 150px;
      }
      span:nth-child(2) {
        border: 0;
        background-color: transparent;
        // margin-left: 100px;
        pointer-events: none;
      }
    }
    .hint {
      position: absolute;
      top: 0;
      left: -128px;
      width: 128px;
      height: 34px;
      border-radius: 5px;
      background: #ce5a69;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        color: #fff;
        &:nth-child(2) {
          font-weight: 700;
        }
      }
      .arrow {
        position: absolute;
        top: 50%;
        right: -10px;
        width: 23.5px;
        height: 23.5px;
        background: #ce5a69;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
}

.correction-popup-actions {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 68px;
  background-color: #fff;
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 129px;
    height: 45px;
    max-width: 100%;
    background: #f0f0f0;
    border: 1px solid #ffd600;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 10px;
    margin-right: 7px;
    cursor: pointer;
    &.save {
      border: 1px solid $main-green;
    }
    &.remove {
      border: 1px solid #ce5a69;
    }
  }
}
