.apla-box {
  flex-direction: column;
  .inputs {
    width: 150px;
    column-count: 2;
    column-gap: 10px;
  }
  .checkbox {
    text-transform: uppercase;
  }
  // .header {
  //   display: flex;
  //   justify-content: space-between;
  //   label {
  //     text-transform: lowercase;
  //   }
  //   .hide-box {
  //     pointer-events: all !important;
  //     position: relative;
  //     z-index: 1;
  //   }
  // }
}
