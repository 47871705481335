* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
$main-green: #1ab394;
$main-green-hover: #3a7764;
$main-green-click: #55af94;
$warning: #f8ac59;
$warning-hover: #d28a3a;
$warning-click: #f7a54a;
$danger: #ed5565;
$error: #ed5565;
$danger-hover: #b24e55;
$variant-blue: #23c6c8;
$variant-blue-hover: #1daeaf;
$has-correction: #ff7e59;
$has-correction-hover: #f46036;
$dark-white: #e4e5e6;
$border-grey: #e4e5e6;
$border-green: #55af94;
$icon: #303a46;
$button-black: #303a46;
$button-black-active: #455262;
$black: #1d1d1d;
$popup_title: #303a46;
$cancel-button: #ffffff;
.text-box-focus {
  border: 1px solid $main-green !important;
}
.table {
  width: 100%;
}
#mod_ecommerce {
  box-sizing: border-box !important;
  font-family: 'Open Sans', sans-serif;
  color: $black;
  ul {
    padding: 0;
  }
  .collapse {
    display: block !important;
  }

  .error {
    color: red;
  }

  input,
  textarea {
    outline: none;
  }

  body {
    background: #f4f4f4 !important;
  }
  .form-control {
    border: 1px solid #e5e6e7;
    background-color: #fff;
    border-radius: 5px;
    font-size: 0.9rem;
    transition:
      border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
    &:focus {
      color: #495057;
      border-color: #1ab394;
    }
  }
  .form-control-sm {
    height: 29px !important;
    padding: 2px 10px;
  }
  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 5px 10px;
    font-size: 13px;
    line-height: 1.5;
    border-radius: 5px;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  .btn-group {
    .btn {
      &:hover {
        z-index: 10;
      }
    }
  }
  .btn-sm {
    padding: 5px 10px;
    font-size: 13px;
    line-height: 1.5;
    border-radius: 5px;
  }
  .btn-default {
    color: inherit;
    background: #fff;
    border: 1px solid #e7eaec;
  }
  .btn-white {
    color: inherit;
    background: white;
    border: 1px solid #e7eaec;
    &:hover {
      border: 1px solid #d2d2d2;
    }
    &:focus {
      border: 1px solid #d2d2d2;
    }
  }
  .btn-rounded {
    border-radius: 50px;
  }
  .checkbox {
    position: relative;
    display: flex;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 300;
    color: $black;
    margin-bottom: 10px;
    padding-left: 12px;
    // margin: 0;
    margin-top: 0 !important;
    cursor: pointer;
    white-space: break-spaces;
    .checkmark {
      height: 6px;
      width: 6px;
      background: #ffffff;
      border: 0.5px solid #d9d9d9;
      // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      margin-right: 10px;
      position: absolute;
      top: 50%;
      left: 2px;
      transform: translateY(-50%);
    }
    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    input[type='checkbox']:checked ~ .checkmark {
      background: $main-green;
      border: 0.5px solid #ffffff;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    }
  }
  .custom-checkbox-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
  }
  .real-checkbox {
    display: flex;
    align-items: center;
    font-weight: 400 !important;
    cursor: pointer;
    input {
      accent-color: $black;
      margin-right: 15px;
      margin-top: 0 !important;
      width: 12px;
      height: 12px;
    }
  }
  .dropdown {
    width: 100%;
    &.blur {
      filter: blur(1.5px);
    }
    &.visible {
      display: block;
      z-index: 11;
    }
    li {
      list-style: none;
      padding: 10px 15px;
      font-weight: 300;
      color: $black;
      border-radius: 5px;
      font-size: 10px;
      font-family: 'Open Sans', sans-serif;
      cursor: pointer;
      &:nth-child(2n + 2) {
        font-weight: 500;
      }
      &:hover {
        background: #f5f5f5 !important;
      }
    }
    .custom-text {
      display: flex;
      flex-direction: column;
      padding: 14px;
      padding-top: 0;
      span {
        color: $black;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 3px;
      }
      input {
        background: #f4f4f4;
        border-radius: 5px;
        width: 100%;
        height: 26px;
        padding: 8px 10px;
        font-size: 8px;
        border: 0;
      }
    }
  }
  .custom-checkbox {
    cursor: pointer;
  }
  .custom-checkbox input[type='checkbox'] {
    display: none;
  }

  /* Stylizacja checkmarka */
  .custom-checkbox .checkmark {
    width: 12px;
    height: 12px;
    background-color: #fff;
    display: inline-block;
    position: relative;
    // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border: 0.5px solid #d9d9d9;
    border-radius: 3px;
    margin-right: 10px;
  }

  /* Stylizacja zaznaczonego checkboxa */
  .custom-checkbox input[type='checkbox']:checked + .checkmark {
    border: 1px solid $main-green;
    box-shadow: none !important;
  }

  /* Stylizacja zaznaczonego checkboxa - dodanie symbolu "check" */
  .custom-checkbox input[type='checkbox']:checked + .checkmark::after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background: url('../assets/checkbox.svg');
  }
  .dropdown-checkbox {
    .checkbox {
      max-height: 26px !important;
      margin: 0 !important;
      padding-left: 17px;
    }
    .checkmark {
      border-radius: 0;
      border: 1px solid $black;
      box-shadow: 0 !important;
      width: 10px;
      height: 10px;
    }
    input[type='checkbox']:checked ~ .checkmark {
      background: unset;
      border: 1px solid $main-green;
      box-shadow: 0 !important;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 7px;
        height: 7px;
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background: url('../assets/checkbox.svg');
      }
    }
  }

  button {
    cursor: pointer;
    transition: 0.2s ease-out !important;
    &:hover {
      //font-weight: 700 !important;
    }
    &.show-all-popup {
      &:hover {
        font-weight: 300 !important;
      }
    }
  }

  .row {
    margin: 0;
  }

  p {
    margin: 0;
  }

  .hidden {
    display: block !important;
  }

  .swiper {
    width: 100%;
  }
  .slide-prev-btn,
  .slide-next-btn {
    position: absolute;
    z-index: 7;
    top: 50%;
    // transform: translateY(-50%);
    background-color: transparent;
    border: 0;
    &::after {
      content: '';
      width: 25px;
      height: 25px;
      display: block;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url('../assets/navigate.svg');
    }
  }
  .slide-prev-btn {
    left: 0;
    transform-origin: center;
    transform: rotate(-180deg) translateY(50%);
    z-index: 2;
  }
  .slide-next-btn {
    right: 0;
    transform: translateY(-50%);
  }

  .slide-prev-btn,
  .slide-next-btn {
    &.disactive {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  .ql-editor {
    padding: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none !important;
    }
    &:has(p) {
      overflow-y: scroll;
    }
  }

  .ql-editor.ql-blank::before {
    left: 0 !important;
    font-size: 12px;
    font-style: unset;
    color: #989898 !important;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400 !important;
  }

  .ql-container.ql-snow {
    .ql-editor.ql-blank::before {
      left: 10px !important;
    }
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
    color: var(--toastify-text-color-success);
    background: $main-green;
  }

  .ql-toolbar.ql-snow {
    display: flex;
    align-items: center;
    height: 38px !important;
    padding: 5px !important;
    .ql-formats {
      margin: 0 !important;
      &::after {
        display: none !important;
      }
    }
  }

  .ql-container.ql-snow {
    height: 115px;
    padding: 5px 10px;
    border-radius: 0 0 8px 8px;
  }

  .ql-toolbar.ql-snow {
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 8px 8px 0 0;
    .ql-color-picker {
      margin: 0 !important;
    }
  }
}
