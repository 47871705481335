.main-app-component {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background-color: #f3f3f4;
  .menu-section {
    display: block;
    min-height: 100vh;
    width: 220px;
    position: relative;
    z-index: 2;
    .helpdesk-banner {
      width: 100%;
      height: 16px;
      position: absolute;
      bottom: 33px;
      left: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url('../assets/helpdesk.svg');
      a {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  .content-section {
    position: relative;
    width: 100%;
    // height: 100vh;
  }
  .bg-clear {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100%;
    display: none;
    &.active {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .bg-blur {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
    display: none;
    &.active {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .bg-dark {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    width: 100vw;
    height: 100%;
    background-image: url('../assets/bg-rm.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: none;
    &.active {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
  .bg-shadow {
    position: fixed;
    top: 0;
    left: -10%;
    z-index: 6;
    width: 110vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);

    backdrop-filter: blur(4px);
    display: none;
    &.active {
      display: block;
    }
  }
}

// @media (max-width: 1920px) {
//   .main-app-component {
//     .content-section {
//       width: 90%;
//     }
//   }
// }

// @media (max-width: 1200px) {
//   .main-app-component {
//     .content-section {
//       width: 88%;
//     }
//   }
// }

// @media (max-width: 1100px) {
//   .main-app-component {
//     .content-section {
//       width: 86%;
//     }
//   }
// }

// @media (max-width: 991px) {
//   .main-app-component {
//     .content-section {
//       width: 84%;
//     }
//   }
// }
