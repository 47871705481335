.author-info {
  width: 100%;

  &__group {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  &__deparment,
  &__name {
    color: $black !important;
    font-family: 'Montserrat', sans-serif;
  }

  &__department {
    text-transform: uppercase;
    margin-right: 6px;
    width: 60px;
    display: flex;
    align-items: end;
    font-size: 12px;
  }

  &__name {
    font-size: 12px;
  }
}
