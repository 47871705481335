.radio-btn-txt-box {
  flex-direction: column;
  .inputs {
    padding-left: 20px;
    height: 200px;
    overflow: scroll;
  }
  .input-group {
    position: relative;
    margin-bottom: 10px;
    .checkbox {
      margin-bottom: 3px;
    }
    .conector {
      position: absolute;
      top: 20%;
      left: 5px;
      height: 24px;
      width: 12px;
      border-left: 0.7px solid #d9d9d9;
      border-bottom: 0.7px solid #d9d9d9;
      border-radius: 0 0 0 5px;
    }
    .text-input {
      background: #f4f4f4;
      border: 0.7px solid #d9d9d9;
      border-radius: 3px;
      width: 435px;
      padding: 7px 8px;
      margin-left: 17px;
      pointer-events: none;
      opacity: 0.3;
      &.active {
        pointer-events: all;
        opacity: 1;
      }
    }
  }
  // .header {
  //   display: flex;
  //   justify-content: space-between;
  //   .hide-box {
  //     pointer-events: all !important;
  //     position: relative;
  //     z-index: 1;
  //   }
  // }
}
