.add-product-box {
  flex-direction: column;
  align-items: center;
  width: 100%;
  .slide-next-btn {
    right: 10px !important;
    z-index: 2 !important;
  }
  .slide-prev-btn {
    z-index: 2;
  }
  .swiper {
    z-index: 2 !important;
  }
  &.show {
    .swiper {
      overflow: visible !important;
    }
  }
  .add-product {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    .product {
      position: relative;
      cursor: pointer;
      width: 50px;
      height: 30px;
      font-family: 'Open Sans', sans-serif;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      color: white;
      font-weight: 400;
      border-radius: 50px;
      background-color: $variant-blue;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: auto;
    }
    .add-new-product {
      width: 50px;
      height: 30px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      color: #212529;
      border-radius: 50px;
      font-size: 20px;
      background-color: white;
      border: 1px solid #e4e5e6;
      margin-right: auto;
      &:hover {
        background-color: $dark-white;
      }
    }
    #tooltip {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      bottom: 102%;
      left: calc(50% - 40px);
      width: 80px;
      height: 142px;
      background-color: #4d4d4d;
      border-radius: 10px;
      color: white;
      padding: 4px;
      opacity: 0; /* Set initial opacity to 0 */
      -webkit-transition: opacity 400ms ease-in-out;
      -moz-transition: opacity 400ms ease-in-out;
      -o-transition: opacity 400ms ease-in-out;
      transition: opacity 400ms ease-in-out; /* Transition for the opacity change */
      animation: visible 0.4s ease-in-out;
      pointer-events: none; /* Avoids mouse events when tooltip is invisible */
      .image-view {
        position: relative;
        margin-bottom: 4px;
        .hover-shadow {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          .shadow {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;
            background-color: #4d4d4d;
            transition: opacity 0.2s ease-in-out;
          }
          .loop {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
          }
          &:hover {
            .shadow {
              opacity: 0.7;
            }
            .loop {
              opacity: 1;
            }
          }
        }
      }
      .view {
        overflow: hidden;
        width: 72px;
        height: 111px;
        min-height: unset;
        border: 0;
        ::before {
          display: none;
        }
      }
      &.tooltipVisible {
        opacity: 1; /* Full opacity when tooltip is supposed to be visible */
        pointer-events: all;
      }
      img {
        padding: 0;
        object-fit: cover;
      }
      span {
        font-size: 10px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        max-height: 27px;
        &:hover {
          background-color: #626262;
          border-radius: 50px;
        }
      }
      #triangle {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #4d4d4d;
      }
    }

    .slide-prev-btn {
      left: -10px !important;
      transform: translateY(-50%) rotate(-180deg) !important;
    }

    .slide-next-btn {
      right: -10px !important;
      transform: translateY(-50%);
    }
  }
}

.product-tooltip {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 80px;
  height: 142px;
  background-color: #4d4d4d;
  border-radius: 10px;
  color: white;
  padding: 4px;
  opacity: 0; /* Set initial opacity to 0 */
  -webkit-transition: opacity 400ms ease-in-out;
  -moz-transition: opacity 400ms ease-in-out;
  -o-transition: opacity 400ms ease-in-out;
  transition: opacity 400ms ease-in-out; /* Transition for the opacity change */
  animation: visible 0.4s ease-in-out;
  pointer-events: none; /* Avoids mouse events when tooltip is invisible */
  .image-view {
    position: relative;
    margin-bottom: 4px;
    cursor: pointer;
    .hover-shadow {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      .shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        background-color: #4d4d4d;
        transition: opacity 0.2s ease-in-out;
      }
      .loop {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
      &:hover {
        .shadow {
          opacity: 0.7;
        }
        .loop {
          opacity: 1;
        }
      }
    }
  }
  .view {
    overflow: hidden;
    width: 72px;
    height: 111px;
    min-height: unset;
    border: 0;
    ::before {
      display: none;
    }
  }
  &.tooltipVisible {
    opacity: 1; /* Full opacity when tooltip is supposed to be visible */
    pointer-events: all;
  }
  img {
    padding: 0;
    object-fit: cover;
  }
  span {
    font-size: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    max-height: 27px;
    cursor: pointer;
    &:hover {
      background-color: #626262;
      border-radius: 50px;
    }
  }
  #triangle {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #4d4d4d;
  }
}
