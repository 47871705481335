.change-deadline-popup {
  overflow: unset;
  .input-group {
    width: 100%;
    max-width: 334px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.with-suffix {
      display: flex;
      align-items: center;
    }
  }
  .input-suffix {
    text-transform: none;
  }
  span {
    margin-bottom: 10px;
    display: inline-block;
    text-align: center;
  }
  .calendar-deadline,
  input {
    width: 100%;
  }
  .calender-data {
    height: 28px;
    max-height: 28px;
  }
  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
}
