.my-project-box-container {
  overflow: hidden;
  &.box-client {
    top: 8px;
  }
  .boxes-container {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: flex-start;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
