.order-edit-info {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__text-content {
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
    margin-left: 10px;
    strong {
      font-weight: 700;
      font-style: italic;
    }
  }
}
