ul.sortable-list {
  padding: 0 !important;
}
.text-box {
  position: relative;
  min-width: 544px;
  width: calc(33.33% - 9px);
  height: 360px;
  padding: 13px 22px;
  border-radius: 10px;
  border: 1px solid #e4e5e6;
  background: #fff;
  margin-right: 12px;
  margin-top: 12px;
  &:last-child {
    margin-right: 0;
  }
  .switch-container {
    display: inline-block;
    width: 25px;
    height: 16px;
    position: relative;
    cursor: pointer;
  }

  .switch {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-color: #ccc;
    position: relative;
    transition: background-color 0.3s;
  }

  .switch.on {
    background-color: $main-green;
  }

  .switch.off {
    background-color: #ccc;
  }

  .switch-button {
    width: 14px;
    height: 14px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: left 0.3s ease-in-out;
  }

  .switch.on .switch-button {
    left: 10px;
  }

  .switch.off .switch-button {
    left: 2px;
  }

  &.hidden {
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      background: #ffffff;
      opacity: 0.8;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 5px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    height: 30px;
    margin-bottom: 20px;
  }

  &__title,
  &__subtitle {
    color: $black;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
  }

  &__subtitle {
    margin-left: 5px;
    font-weight: 300;
  }

  &__logo {
    margin-right: 15px;
    img {
      width: 29px;
      height: 29px;
    }
  }

  &__hide-box {
    margin-left: auto;
    z-index: 1;
    .checkbox {
      margin-bottom: 0 !important;
      margin-left: auto !important;
    }
  }
}
