.color-box {
  left: 0;
  display: flex;
  width: 70px;
  height: 50px !important;
  top: 50%;
  transform: translateY(-50%);
  span {
    font-size: 8px;
    margin: 0;
  }
  .text-filed {
    align-items: center;
    display: flex;
    margin-right: 19px;
  }
  .colors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    label {
      position: relative;
      width: 15px;
      height: 15px;
      background: #ffffff;
      border: 1px solid #f4f4f4;
      border-radius: 5px;
      margin-bottom: 3px;
      display: flex;
      cursor: pointer;
      .color-view {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      input {
        width: 0;
        height: 0;
        display: none;
      }
      &.chosen {
        background: #f4f4f4;
      }
    }
  }
  .divider {
    position: absolute;
    top: 50%;
    left: 67%;
    transform: translateY(-50%);
    width: 7.5px;
    height: 34px;
    border-top: 1px solid #f4f4f4;
    border-left: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    border-radius: 5px 0 0 5px;
    &::after {
      content: '';
      height: 1px;
      width: 15px;
      display: block;
      background-color: #f4f4f4;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -100%);
    }
  }
  .default-1 {
    color: $black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 8px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
