.compare-variants {
  .wrapper {
    padding: 20px 30px;
  }

  span {
    display: inline-block;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px !important;
    &::before {
      display: none !important;
    }
  }

  .col {
    width: 49.45%;
    background-color: #fff;
    border-radius: 8px;
    min-width: 500px;
    border: 1px solid #e4e5e6;
  }
  .field-name,
  .field-value {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
  }

  .field-name {
    align-items: center;
    color: $black;
    display: flex;
    font-weight: 700;
    padding: 9px 0px;
    white-space: break-spaces;
    width: 200px;
    margin-right: 20px;
  }

  .list {
    list-style: none;
    min-height: 650px;
    height: calc(100vh - 230px);
    padding: 15px !important;
    overflow-y: auto;
    .list-element {
      display: flex;
      align-items: center;
      padding: 9px !important;
      &.correction {
        opacity: 0.3;
        &.dif {
          opacity: 1;
          .field-name {
            color: red;
          }
        }
      }
    }
  }

  .field-value {
    width: 100%;
    &.product {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .header {
    display: flex;
    align-items: center;
    padding: 15px;
    padding-bottom: 0;
    margin-bottom: 18px;
    .logo {
      margin-right: 15px;
    }

    .title-section {
      display: flex;
      align-items: center;
    }
    .title,
    .subtitle {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      margin: 0 !important;
    }

    .subtitle {
      font-weight: 400;
      font-style: italic;
    }

    .page_component_wrapper {
      width: auto;
      margin-left: auto;
      .text-section {
        display: none;
      }

      .bookmark-wrapper {
        padding: 0;
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #e4e5e6;

    button {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      font-size: 12px;
      border: 0;
      padding: 4px 12px;
      color: #ffffff;
      border-radius: 8px;
      &.first-button {
        background-color: $main-green;
        margin-right: 7px;
      }
      &.second-button {
        background-color: #5465ff;
      }
    }
  }
  //ABC-242, display custom size list and default size list
  .reformat-sizes {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
    min-height: 30px;
    border-radius: 8px;
    border: 1px solid #e4e5e6;
    padding: 5px 10px;
    overflow: auto;
    .list {
      margin-right: 15px;
      padding: 0 !important;
      list-style: none;
      margin-bottom: 15px;
      width: 130px;
      height: auto;
      min-height: unset;
    }
    .list-header {
      font-weight: 700;
    }
  }

  .dropdown-box {
    width: auto;
    margin-right: 3px;
    .custom-input {
      width: 150px;
    }

    .arrow-down {
      left: 90%;
    }

    .dropdown-list {
      max-width: 150px;
      margin-top: 3px;
    }
    .dropdown-list__container {
      // max-width: 100px;
      min-width: unset !important;
    }
  }
}

@media (max-width: 1200px) {
  .compare-variants {
    .row {
      flex-direction: column;
    }

    .col {
      margin-bottom: 28px;
      width: 100%;
    }
  }
}
