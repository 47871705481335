.specification-section {
  .box-wrapper {
    position: relative;
    height: calc(100vh - 46px);
    overflow-y: auto;
    padding: 25px 22px;
  }

  .title-section {
    display: flex;
    align-items: center;
  }

  h2,
  p,
  .collapse-button {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: $black;
    font-weight: 700;
    text-align: left;
  }

  h2 {
    margin: 0 15px 0 0px !important;
  }

  p {
    font-weight: normal;
    margin-top: 15px !important;
  }

  .collapse-button {
    border: 0;
    background-color: transparent;
    margin: 0;
    cursor: pointer;
    font-weight: normal;
    color: #373939;
    transition: 0.2s ease-out;
    &:hover {
      text-decoration: underline;
    }
  }

  .box {
    flex-direction: column;
    border-radius: 10px;
    padding: 13px 22px;
  }
}
