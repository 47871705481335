.calendar-deadline {
  position: relative;
  max-height: 30px;
  height: 30px;
  border-radius: 5px;
  .presentation {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 100%;
  }
  .arrow-down {
    top: 44%;
    border-color: #989898 transparent transparent;
    border-style: solid;
    border-width: 6px 4px 0;
    width: 0;
    height: 0;
    left: 94%;
    position: absolute;
  }
  &.blocked {
    pointer-events: none;
  }
  &.visible {
    z-index: 9;
  }
  .calender-data {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    height: 30px;
    background-color: #fff !important;
    border: 1px solid #e4e5e6 !important;
    cursor: pointer;
    border-radius: 8px;
    line-height: 14px;
    &::placeholder {
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      color: #989898;
    }
    &:focus {
      border: 1px solid $main-green !important;
    }
  }
  .calendar-container {
    width: 100% !important;
    //max-width:none !important;
    max-width: 265px !important;
    border-radius: 8px;
    overflow: hidden;
  }
  .calendar {
    background-color: #fff;
    max-width: none !important;
    // padding: 5px 30px;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
      text-align: center;
      margin-bottom: 0;
      // padding-bottom: 15px !important;
    }
    li {
      color: #333;
      width: 30px;
      height: 30px;
      font-size: 10px;
      padding: 5px;
      margin: 0 2px !important;
      max-width: 40px;
      font-family: 'Open Sans', sans-serif;
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      &.is-today {
        border: 1px solid $main-green;
        border-radius: 4px;
      }
    }
  }
  .wrapper {
    position: absolute !important;
    top: calc(100% + 3px);
    left: 0;
    min-width: 250px;
    width: 100%;
    margin-top: 0 !important;
    border-radius: 8px;
    border: 0.7px solid #e2e2e2;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    height: auto !important;
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 100% !important;
    max-width: none !important;
    min-width: unset;
    margin-bottom: 0;
    padding-bottom: 0;
    button,
    p {
      padding: 5px;
      margin: 5px;
      background-color: transparent;
      border: 0;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
    }
    #prev,
    #next {
      background: transparent !important;
      box-shadow: unset !important;
      color: black !important;
    }
    button {
      cursor: pointer;
    }
  }
  li {
    height: 33px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .weeks {
    li {
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.3);
      text-transform: uppercase;
      &.weekend {
        background: rgba(0, 0, 0, 0.03);
        border-radius: 5px 5px 0 0;
      }
    }
  }
  .days {
    padding-bottom: 15px !important;
    .weekend {
      background: rgba(0, 0, 0, 0.03);
    }
    .chosen {
      background: $main-green;
      color: #fff;
      border-radius: 4px;
    }
  }
  .inactive {
    width: 33px;
    height: 20px;
    &.weekend {
      background: rgba(0, 0, 0, 0.03);
      border-radius: 0 0 5px 5px;
    }
  }
  .time-input {
    width: 100%;
    background-color: #fff;
    padding: 10px;
    max-width: none !important;
    display: flex;
    justify-content: space-between;

    label {
      display: flex;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      color: $black;
      background: #ffffff;
      border: 0.7px solid $black;
      border-radius: 5px;
      width: 100%;
      max-width: 217px;
      padding: 6px;
      text-transform: uppercase;
      position: relative;
      input {
        width: 10px !important;
        border: 0 !important;
        padding: 0 !important;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        font-size: 10px;
        margin: 0 5px;
        &.hour {
          margin-left: 10px;
        }
      }
    }
  }
  .set-up {
    background: #55a630 !important;
    border-radius: 5px;
    border: none;
    outline: none;
    text-align: center;
    color: #ffffff !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 4px 12px;
  }

  .error {
    position: static !important;
    display: block !important;
    background-color: #fff;
    width: 100%;
    display: block;
    padding: 0 10px 10px 10px;
    font-size: 12px;
    transform: unset !important;
  }

  .not-validated {
    border: 1px solid $danger;
  }
}
