.box-info-popup {
  justify-content: center;
  align-items: center;
  height: 573px;
  &.extended {
    span {
      margin-bottom: 0 !important;
      &.extended {
        margin-bottom: 124px !important;
      }
    }
  }
  button {
    opacity: 1 !important;
    pointer-events: all !important;
  }
}
