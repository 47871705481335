header {
  display: flex !important;
  background-color: #3d3d3d;
  padding: 13px 15px;
  display: flex;
  justify-content: space-between;
  .breadcumps {
    position: relative;
    display: flex;
    align-items: center;
    span {
      font-family: 'Open Sans', sans-serif;
      color: #ffffff;
      font-size: 12px;
      margin-right: 10px;
      &:nth-child(1) {
        font-weight: 700;
      }
    }
    .prev {
      margin-right: 15px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      &::before {
        content: '<';
        color: #ffffff;
        font-family: 'News Cycle', sans-serif;
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    .hint {
      padding: 0 10px;
      height: 80%;
      display: flex;
      align-items: center;
      &::before {
        content: '';
        display: block;
        width: 9px;
        height: 9px;
        background-image: url('../assets/hint.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    button {
      background-color: transparent;
      color: #ffffff;
      border: 0;
      padding: 0 10px;
      position: relative;
    }
    button,
    .hint {
      position: relative;
      &::after {
        content: '';
        display: block;
        height: 90%;
        width: 1px;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
    .logout {
      font-weight: 700;
      &::after {
        display: none;
      }
    }
  }
}
