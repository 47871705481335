.team-box-container {
  padding: 14px;
  h1 {
    margin: 0 !important;
  }
}
.team-box-container {
  width: 100% !important;
}
.title-row {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  h1 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    margin: 0 !important;
  }
  svg {
    margin-right: 10px;
  }
}
.team-box-container {
  height: 1014px;
  width: 749px;
  .boxes-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: space-between;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    .project-type-row-container {
      width: 100%;
      .title-wrapper {
        margin-bottom: 10px;
        p {
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #373939;
        }
      }
      .content {
        display: flex;
        justify-content: start;
        align-items: start;
        flex-wrap: wrap;
      }
    }
  }
  .role-option {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 106%;
    text-transform: uppercase;
    color: $black;
  }
  .select-team:last-child {
    margin-top: 5px;
  }

  .option-select {
    height: 36px;
  }
  .role-box-container {
    // display: flex;
    display: none;
    flex-direction: row;
    align-items: center;
    position: relative;
    .card {
      height: 53px;
      width: 36px;
      border: 0.5px solid #f4f4f4;
      box-shadow: 0px 1px 2px rgba(39, 51, 61, 0.1);
      border-radius: 100px;
      margin: 5px;
      display: flex;
      flex-direction: column;
      .role-name {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
        font-size: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        letter-spacing: -0.2px;
        color: $black;
      }
    }
    .main-card {
      width: 55px;
      height: 80px;
      background: linear-gradient(0deg, #ffffff, #ffffff),
        rgba(255, 255, 255, 0.0001);
      border: 0.5px solid #f4f4f4;
      box-shadow: 0px 1px 2px rgba(39, 51, 61, 0.1);
      border-radius: 100px;
      display: flex;
      flex-direction: column;

      .role-name {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 7px;
        line-height: 10px;
        text-align: center;
        letter-spacing: -0.2px;
        color: $black;
      }
    }
  }
}
