.action-data {
  z-index: 2;
  position: fixed;
  left: calc(50% - ((590px - 220px) / 2));
  bottom: 50px;
  background: #ffffff;
  width: auto;
  gap: 22px;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-radius: 100px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  &.disactive {
    display: none;
  }
  &.action-data-correction {
    left: calc(50% - ((385px - 220px) / 2));
  }
  button {
    color: #ffffff;
    border: 0;
    width: 158px;
    height: 31px;
    display: flex;
    font-size: 13px;
    align-content: center;
    padding: 9px 0;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    border-radius: 50px;
    font-weight: 400;
    cursor: pointer;
    transition: 0.2s ease-out;
    &.send {
      background: $main-green;
      border: none;
      outline: none;
      // margin-left: 450px;
      &:hover {
        background: $main-green-hover;
      }
      &:active {
        background: $main-green-click;
      }
    }
    &.save {
      background: $warning;
      border: none;
      outline: none;
      &:hover {
        background: $warning-hover;
      }
      &:active {
        background: $warning-click;
      }
    }
    &.cancel {
      background: $danger;
      border: none;
      outline: none;
      &:hover {
        background: $danger-hover;
      }
      &:active {
        background: $danger;
      }
    }
    &.disabled {
      pointer-events: none;
      background-color: #e6e6e6;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  // max-width: 647px;
  padding: 0;
  button {
    //box-shadow: inset 0px -5px 10px rgba(135, 193, 108, 0.15);
    border-radius: 50px;
    max-width: 100%;
    font-size: 13px !important;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    padding: 0 12px;
    height: 30px;
    color: #ffffff;
    line-height: 10px;
    &.capture,
    &.edit,
    &.duplicate,
    &.add-corection,
    &.accept,
    &.observe,
    &add-counter,
    &.stop-observe,
    &.forwoard,
    &.draft-edit,
    &.draft-duplicate,
    &.restore,
    &.duplicate,
    &.download,
    &.format,
    &.add-counter {
      background: white !important;
      border: 1px solid $button-black;
      color: $button-black !important;
      outline: none;
      font-size: 13px !important;
      &:hover {
        background: $button-black !important;
        color: white !important;
      }
      &:active {
        background-color: $button-black-active !important;
        color: white !important;
      }
    }
    &.fill-form,
    &.add-comment {
      border: 1px solid #ffd600;
      // width: 35%;
    }
    &.remove,
    &.reject {
      background: white !important;
      border: 1px solid #b24e55;
      color: #b24e55 !important;
      outline: none;
      &:hover {
        background: #b24e55 !important;
        color: white !important;
      }
      &:active {
        border: 1px solid #db5f68 !important;
        background-color: #db5f68 !important;
        color: white !important;
      }
    }
    &.casual {
      pointer-events: none;
      cursor: default;
      border: none !important;
      outline: none !important;
      color: White !important;
      background-color: #d8d8d8 !important;
    }
  }
}

@media (max-width: 1100px) {
  .action-data {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    z-index: 99;
    button {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 769px) {
  .action-data {
    width: 100%;
    left: 0;
  }
}
