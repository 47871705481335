.navbar-default {
  z-index: 2 !important;
}
span.correction-counter-local {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  font-family: inherit;
}
span.correction-counter-staging {
  color: #999c9e;
  font-size: 14px;
  font-weight: 700;
  font-family: inherit;
}
span.correction-text {
  color: #999c9e;
  font-size: 14px;
  font-family: inherit;
}
li.correction-counter {
  list-style: none;
}
span.version-text {
  color: #999c9e;
  display: flex;
  align-content: center;
  font-size: 14px;
  font-family: inherit;
}
span.version-name-local {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  font-family: inherit;
}
span.version-name-staging {
  color: #999c9e;
  font-size: 14px;
  font-weight: 700;
  font-family: inherit;
}
li.version-name {
  list-style: none;
  display: flex;
  padding: 0 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-right: 1px solid #999c9e;
  border-left: 1px solid #999c9e;
}
body.mini-navbar .navbar-default.navbar-static-side {
  width: 70px !important;
}

@media (min-width: 768px) {
  #page-wrapper {
    padding: 0 !important;
    overflow: hidden;
  }
  body.mini-navbar #page-wrapper {
    margin: 0 0 0 70px !important;
  }
}

body {
  background-color: #fff;
}

.navbar-static-side {
  background-color: #2f4050;
}

.row.border-bottom {
  height: 46px !important;
}

.navbar-static-top {
  z-index: 1 !important;
}

.navbar-top-links {
  display: flex;
  align-items: center;
}

.notification-bell {
  position: relative;
  padding: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &::before {
    content: '';
    position: static !important;
    display: block;
    width: 23px;
    height: 23px;
    background-image: url('../assets/notifiIcon.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  a {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
  }

  span {
    font-size: 10px;
    font-weight: 700;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    right: 5px;
    background-color: red;
    border-radius: 50%;
    color: #ffffff;
  }
}

.hard-reload {
  width: 23px;
  height: 23px;
  margin: 0 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  font-size: 23px !important;
  color: #989a9c !important;
  list-style: none;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
