.total-remove-popup {
  max-width: 100%;
  height: 573px;
  width: 507px;
  .popup__wrapper {
    background: rgba(0, 0, 0, 0.6);
  }
  .popup__text {
    color: #fff;
    white-space: nowrap;
  }
  .popup__action-info {
    color: #fff;
  }
}

.bg-rm-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('../../../assets/bg-rm.png');
}
