.dropdown-area {
  position: relative;
  .presentation {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 100%;
  }
  .arrow-down {
    top: 44%;
    border-color: #989898 transparent transparent;
    border-style: solid;
    border-width: 6px 4px 0;
    width: 0;
    height: 0;
    left: 30%;
    position: absolute;
  }
  &.showbg {
    z-index: 9;
  }
  &.blocked {
    pointer-events: none;
  }
  &.visible {
    z-index: 7;
    input {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .campaing-input {
    z-index: -1;
    line-height: 14px;
    cursor: pointer;
    border-radius: 8px !important;
    background: #ffffff;
    border: none;
    &::placeholder {
      font-size: 8px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      color: #7a7a7a;
    }
    &.showbg {
      position: relative;
      z-index: 9999999;
    }
    &.not-validated {
      border: 1px solid $danger;
    }
  }

  ul {
    max-height: 221px;
    overflow-y: auto;
  }
  .dropdown-container {
    position: absolute;
    z-index: 3;
    left: 0;
    top: calc(100% + 3px);
    background: #ffffff;
    width: 100%;
    .actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;
      button {
        border: none;
        outline: none;
        background-color: #55a630;
        border-radius: 5px;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        cursor: pointer;
        &.add {
          font-weight: 400;
          padding: 4px 12px;
          width: 100%;
          max-width: 100%;
          color: #ffffff;
          margin-left: 0 !important;
          background-color: $main-green !important;
          border-radius: 50px;
        }
        &.search {
          color: $black;
          width: 140px;
          max-width: 100%;
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          &::after {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../assets/search.svg');
            margin-left: 5px;
          }
        }
      }
    }
  }

  input {
    background: #ffffff;
    border: 0.7px solid #e2e2e2;
    // border-radius: 5px;
    width: 334px;
    max-width: 100%;
    padding: 6px;
    background: #ffffff;
    border: 0.7px solid #ffffff;

    border-radius: 5px !important;
  }
  .error {
    display: block;
    position: absolute;
    left: 0;
    bottom: -15px;
    font-size: 10px;
    &.campaign {
      position: static;
    }
  }
}
