.table-pagination {
  display: flex;
  justify-content: flex-end !important;
  padding: 9px 0px !important;
  li {
    list-style: none;
    border: 1px solid #d9d9d9;
    border-right-color: rgb(217, 217, 217);
    border-right-style: solid;
    border-right-width: 1px;
    border-right: 0;

    width: 30px;
    height: 30px;

    cursor: pointer;
    transition: 0.2s ease-out;

    a {
      font-family: 'Open Sans', sans-serif;
      font-size: 10px;
      font-weight: 300;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $black !important;
    }

    &.previous,
    &.next {
      width: auto;
      padding: 5px;
    }

    &.previous {
      border-radius: 5px 0 0 5px;
    }

    &.next {
      border-right: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      border-left: 1px solid #d9d9d9;
      border-radius: 0 5px 5px 0;
    }

    &.selected {
      background: $main-green;
      a {
        color: white !important;
      }
    }

    &:hover {
      background: rgba(217, 217, 217, 0.5);
    }
  }
  .next {
    border-bottom: 0;
    border-left: 0;
    transform: unset;
  }
}
