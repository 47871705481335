.background-src-popup {
  border: 1px solid #e4e5e6;
  background-color: #ffffff;
  display: flex;
  width: 320px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 43px 35px 35px 35px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 20px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
  .title-section {
    margin-bottom: 25px;
    h3 {
      font-family: 'Open Sans', sans-serif;
      text-align: center;
      color: $popup_title;
      font-size: 14px;
      font-weight: 700;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
    }
  }

  .index {
    position: relative;
    margin-bottom: 34px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .input-field {
      width: 100%;
      background: #ffffff;
      border-radius: 8px;
      height: 30px;
      margin-bottom: 15px;
      border: 1px solid $border-grey;
      padding: 3px 10px;
      font-family: 'Open Sans', sans-serif;
      color: $popup_title;
      font-size: 12px;
    }
    .additional-text {
      width: 100%;
      height: 175px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid $border-grey;
      padding: 10px 10px;
      font-family: 'Open Sans', sans-serif;
      resize: none;
      color: $popup_title;
      font-size: 12px;
    }
    &.error-field {
      input {
        filter: drop-shadow(0px 0px 10px #ce5a69);
      }
    }
  }
  .bottom {
    .action-button {
      width: 90px;
      height: 35px;
      border-radius: 50px;
      border: 0;
      color: #ffffff;
      font-family: 'Open Sans', sans-serif;
      &.send {
        background: $main-green;
        border: none;
        outline: none;
        // margin-left: 450px;
        &:hover {
          background: $main-green-hover;
        }
      }
      &.cancel {
        background-color: #e7e7e7;
        color: #787878;
        margin-right: 16px;
        &:hover {
          background: #e7e7e7;
        }
        &:active {
          background: #dddddd;
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    gap: 17px;
    button {
      width: 90px;
      height: 35px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-size: 12px;
      color: #ffffff;
      border-radius: 50px;
      border: 0;
      cursor: pointer;
      margin-right: auto;
      &.save {
        background: $main-green;
        &:hover {
          background: $main-green-hover;
        }
      }

      &.cancel {
        background: $cancel-button;
        border: 1px solid $border-grey;
        color: #212529;
        &:hover {
          background: #e7e7e7;
        }
        &:active {
          background: #dddddd;
        }
      }
    }
  }
}
