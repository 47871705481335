.calender-from-to {
  padding-bottom: 0;
  margin-right: 60px;
  .calender-input {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    span {
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      color: #303a46;
      font-weight: bold;
    }
  }

  .calendar-deadline {
    li {
      &:nth-child(2n + 2) {
        background: transparent !important;
      }

      &.weekend {
        background: #f6f6f6 !important;
      }
    }
  }

  .calender-input {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
  }

  .calendar-deadline {
    margin-left: 10px;
    width: 265px;
  }
  .calender-data {
    width: 100%;
    border-radius: 5px;
    border: 0.5px solid #f2f2f2;
    padding: 3px;
    &.not-validated {
      border: 1px solid $danger !important;
    }
  }
  .actions {
    margin-top: 20px;
    margin-bottom: 20px;
    button {
      width: 164px;
      color: #fff;
      text-align: center;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
      background: #fff;
      &.accept {
        background: $main-green;
      }
      &.cancel {
        border: 1px solid #ffd600;
        color: #ffd600;
      }
    }
  }
}
