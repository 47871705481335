.agention-notes {
  display: flex;
  .info-box {
    display: flex;
    flex-direction: column;
    padding-left: 0 !important;
  }

  .text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 5px;
    span {
      text-align: right;
      font-weight: 600;
      font-size: 12px;
      text-transform: capitalize;
      display: block;
      font-family: 'Open Sans', sans-serif;
      color: $black;
      &:nth-child(2) {
        color: $main-green !important;
        font-size: 10px !important;
      }
      &:nth-child(3) {
        color: #ce5a69 !important;
        font-size: 10px !important;
      }
      &.error,
      &.success {
        font-size: 12px;
        color: #ff0000 !important;
        text-align: left;
        margin-top: 8px;
        text-transform: none;
      }
      &.success {
        color: $main-green !important;
      }
    }
  }

  .text-area-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  // textarea {
  //   width: 100%;
  //   height: 100px;
  //   border-radius: 5px;
  //   resize: none;
  //   border: 1px solid #f4f4f4;
  //   padding: 8px;
  //   font-family: "Open Sans", sans-serif;
  //   font-weight: 300;
  //   color: $black;
  //   font-size: 13px !important;
  //   &.not-validated {
  //     box-shadow: 0px 0px 10px #ce5a69;
  //   }
  // }

  button {
    background: #f0f0f0;
    border: 1px solid rgba(135, 193, 108, 0.3);
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    max-width: 100%;
    margin-top: 8px;
    line-height: normal;
    padding: 4px 12px;
    font-size: 13px;
    &:hover {
      background-color: $main-green;
    }
    &.save {
      background-color: #fff;
      border: 1px solid $button-black;
      color: $button-black;
      outline: none;
      padding: 0 12px;
      font-size: 13px;
      border-radius: 50px;
      height: 30px;
      align-self: flex-end;
      line-height: 11px;
      &:hover {
        background-color: $button-black;
        color: #fff;
      }
    }
  }

  .loader {
    height: 15px;
    width: 15px;
    margin: auto;
  }

  &.team-view {
    margin-bottom: 7px;
    width: 100%;
    padding-left: 20px;
    margin-top: 41px;
    .info-box {
      width: 100%;
    }
    .text-area-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100% !important;
      .success {
        color: $main-green !important;
      }
    }

    span {
      color: $black !important;
    }

    textarea {
      height: 66px;
      border-radius: 5px;
      border: 1px solid #f2f2f2;
      background: #fff;
    }
  }
}
