.logotype-value {
  border: 0 !important;
  padding: 0 !important;
  .logotype-reference {
    font-family: 'Open Sans', sans-serif;
    color: $black;
    font-size: 13px;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 5px;
  }

  .value {
    border: 1px solid #e4e5e6;
    border-radius: 8px;
    min-height: 30px;
    overflow: auto;
    padding: 5px 10px;
    text-align: left;
    font-weight: 300 !important;
  }

  .download-products {
    display: block;
    width: 15px;
    height: 15px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('./../assets/download_bold.svg');
    margin-top: 10px;
  }
}
