.radio-box {
  position: static !important;
  flex-direction: column;
  width: 100%;
  .modification-warning-popup {
    width: 500px;
    height: 400px;
  }
  h3 {
    display: block;
    margin-bottom: 15px;
  }
  .list {
    display: flex;
    &.column-2 {
      position: relative;
    }
  }
  ul {
    position: relative;
    width: 100%;
    padding-top: 5px;
    &.col-num-2 {
      column-count: 2 !important;
      &::after {
        content: '';
        height: 183px;
        width: 1px;
        background-color: #ececec;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.row {
      li {
        margin-right: 70px;
      }
    }
  }
  li {
    list-style: none;
    font-size: 12px;
    margin-bottom: 10px;
    input {
      margin-right: 15px;
    }
    &.percentage,
    &.beside {
      display: flex;
      align-items: center;
      .text {
        color: rgba(0, 0, 0, 0.4);
        border-radius: 8px;
        border: 1px solid #e4e5e6;
        background: #ffffff;
        font-size: 12px;
        width: 100% !important;
        // margin: 0 10px;
        &[data-side='beside'] {
          //pointer-events: none;
          padding: 3px 10px;
          margin-right: 0 !important;
          height: 23px !important;
          width: 110px !important;
          font-size: 12px;
          &:focus {
            border: 1px solid $main-green;
          }
        }
      }
      .text {
        height: 24px;
        margin-top: 0;
        width: 70px;
        font-size: 12px;
        color: $black;
        &.active {
          pointer-events: all;
          opacity: 1;
        }
        &::placeholder {
          font-size: 12px;
          color: #989898 !important;
        }
      }
    }

    &.beside {
      .text {
        width: 50% !important;
      }
    }
  }
  .option-2 {
    overflow-y: auto;
    height: 151px;
    margin-bottom: 10px;
    .children-list {
      column-count: 3;
      overflow-x: hidden;
    }
    li {
      min-width: 150px;
      margin-bottom: 19px;
    }

    .inputs {
      display: none;
      &.option-2-active {
        display: block;
      }
    }
  }
  .checkbox {
    margin-bottom: 0 !important;
  }
  .input-group {
    position: relative;
    margin-bottom: 10px;
    .checkbox {
      margin-bottom: 3px;
    }
    .conector {
      position: absolute;
      top: 34%;
      left: 5px;
      height: 24px;
      width: 12px;
      border-left: 0.7px solid #d9d9d9;
      border-bottom: 0.7px solid #d9d9d9;
      border-radius: 0 0 0 5px;
    }
    .text-input {
      background: #f4f4f4;
      border: 0.7px solid #d9d9d9;
      border-radius: 3px;
      width: 435px;
      padding: 7px 8px;
      margin-left: 32px;
      margin-right: 5px;
      font-family: 'Open Sans', sans-serif;
      &::placeholder {
        font-family: 'Open Sans', sans-serif;
      }
      &.active {
        pointer-events: all;
        opacity: 1;
      }
      &[data-side='bottomside'] {
        margin-right: 0 !important;
        margin-top: 5px !important;
        height: 23px !important;
        background-color: #fff !important;
        border-radius: 8px;
        font-size: 12px;
        padding: 3px 10px;
        &:focus {
          border: 1px solid $main-green;
        }
      }
    }

    &.beside {
      display: flex;
      margin: 0;
    }
  }
}
