.input-text-box {
  flex-direction: column;
  margin-bottom: 6px;
  &.position-inline {
    flex-direction: row;
    .field-desc {
      width: 120px;
      min-width: 140px;
      margin-bottom: 0 !important;
      display: flex;
      align-items: center;
    }
  }

  .input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 5px;
  }

  .price-input {
    font-size: 12px;
    color: $black;
    resize: none;
    width: 100%;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    min-height: 30px;
    border: 0.7px solid #e2e2e2;
    padding: 5px 10px;
    margin-top: 0px;
    height: 30px !important;
    line-height: 20px !important;
    overflow: hidden;
    font-family: 'Open Sans', sans-serif;
    &::placeholder {
      color: #989898 !important;
      font-family: 'Open Sans', sans-serif !important;
      font-weight: 400 !important;
      opacity: 1;
    }
    &:focus {
      border: 0.7px solid $main-green;
    }
  }
}
