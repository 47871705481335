.table-context {
  padding: 8px 9px;
  .statsu-col {
    span {
      text-transform: uppercase;
      color: #ce5a69;
      font-weight: 700;
    }
    &.read {
      span {
        color: $main-green;
      }
    }
  }
  .remove-image {
    display: flex;
    align-items: center;
    margin-top: 8px;
    max-width: 50px;
    img {
      width: 10px;
      height: 10px !important;
      object-fit: contain;
    }

    span {
      color: #ce5a69;
      font-size: 14px;
      transition: 0.1s ease-out;
    }

    figure {
      margin-right: 8px;
    }
    &:hover {
      span {
        font-weight: 600;
      }
    }
  }
  .refresh-button {
    margin-top: 10px;
    font-family: 'Open Sans', sans-serif;
    margin-right: 5px;
    height: 30px;
    background: white !important;
    border: 1px solid #303a46 !important;
    color: #303a46 !important;
    outline: none;
    &:hover {
      background: #303a46 !important;
      color: white !important;
    }
    &:active {
      background-color: #455262 !important;
      color: white !important;
    }
  }
  .wrapper {
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #e4e5e6;
    height: calc(100vh - 83px);
    overflow-x: auto;
    position: relative;
    padding: 9px 20px;
  }
  .no-configuration-info {
    padding: 9px 20px;
  }
  .title {
    // padding: 9px 20px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 4;
    #pageTitle {
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      color: $black;
      font-size: 14px;
      margin: 0 !important;
      display: none;
    }
    .logo {
      img {
        width: 29px;
        height: 29px;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 15px 0;
    position: relative;
    z-index: 0;
    flex-wrap: wrap;

    .show-number {
      margin-right: auto;
      span,
      input {
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        font-size: 10px;
        // color: #d9d9d9;
      }
      input {
        width: 30px;
        height: 20px;
        margin: 0 5px;
        padding: 5px;
        border: 1px solid #d9d9d9;
      }
    }
  }
  .table {
    // padding: 9px 20px;
    overflow-x: auto;
    position: relative;
    .main-row {
      cursor: pointer;
    }
    .row-wrapper {
      transition: 0.2s ease-out;

      &:nth-child(2n + 1) {
        .row {
          &.main-row {
            .td {
              background-color: #f5f5f6;
            }
          }
        }
      }
      &:hover {
        .row {
          &.main-row {
            .td {
              background-color: #d3d3d3;
            }
          }
        }
      }
      &.user_can_not_accept {
        .accept,
        .reject {
          pointer-events: none;
          background-color: #e6e6e6;
        }
      }
      &.accepted {
        .add-corection,
        .accept {
          pointer-events: none;
          background-color: #e6e6e6;
        }
      }
    }
    .tbody,
    .thead {
      min-width: 1280px;
    }
    .thead {
      display: flex;
      div {
        padding: 6px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        color: $black;
        font-size: 14px;
        border: 0.5px solid #d9d9d9;

        &.action {
          display: flex;
          justify-content: space-between;
          align-items: center;
          word-break: break-word;
          min-width: 40px;
        }
      }
      .sort-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        border: 0;
        &::after,
        &::before {
          display: block;
          position: static !important;
          //width: 12px !important;
          //height: 12px !important;
        }
        &::after {
          content: '\2193';
          //background-image: url("../assets/sort-alpha-down-alt.svg");
        }
        &::before {
          content: '\2191';
          //background-image: url("../assets/sort-alpha-down.svg");
        }
        &.desc {
          &::after {
            filter: brightness(0) saturate(100%);
          }
          &::before {
            filter: brightness(0) saturate(100%) invert(67%) sepia(34%) saturate(16%) hue-rotate(7deg) brightness(85%)
              contrast(87%);
          }
        }
        &.asc {
          &::after {
            filter: brightness(0) saturate(100%) invert(67%) sepia(34%) saturate(16%) hue-rotate(7deg) brightness(85%)
              contrast(87%);
          }
          &::before {
            filter: brightness(0) saturate(100%);
          }
        }
      }
    }
    .tbody {
      .row {
        .td {
          display: flex;
          align-items: center;
        }
      }
      &.col-6 {
        &.standard,
        &.special {
          .row {
            .td {
              overflow: auto;
              position: relative;
              &:nth-child(2) {
                position: relative;
                border-right: 0;
                overflow: auto;
                &.person-icon {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  &::after {
                    content: '';
                    display: block;
                    width: 13px;
                    height: 13px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    background-image: url('../assets/person-service.svg');
                  }
                }
              }
              &:nth-child(5) {
                &.ready {
                  color: $main-green;
                }
                &.in-progress {
                  color: #ffd600;
                  text-transform: uppercase;
                  font-weight: 700;
                }
                &.archive {
                  display: flex;
                  justify-content: space-between;
                  color: #d9d9d9;
                  &::after {
                    content: '';
                    width: 14px;
                    height: 14px;
                    display: block;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    background-image: url('../assets/plus.png');
                  }
                }
              }

              &.statsu-col {
                span {
                  text-transform: uppercase;
                  color: #ce5a69;
                  font-weight: 700;
                }
                &.read {
                  span {
                    color: $main-green;
                  }
                }
              }
            }
          }

          &.special {
            .row {
              .td {
                &:nth-child(6) {
                  span {
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 300;
                    color: $black;
                    font-size: 14px;
                    text-transform: none;
                  }
                }
              }
            }
          }
        }
      }

      .row {
        display: flex;
        width: 100%;
        .td {
          position: relative;
          font-family: 'Open Sans', sans-serif;
          font-weight: 300;
          color: $black;
          font-size: 14px;
          border: 0.5px solid #d9d9d9;
          border-top: 0;
          padding: 6px;
          min-width: 40px;
          overflow-x: auto;
          span {
            overflow: hidden;
            word-wrap: break-word;
            &.action-name {
              padding-right: 18px;
            }
          }
          &:nth-child(2n + 2) {
            .td {
              background-color: #f5f5f6;
            }
          }
        }
      }
      .collapse {
        border: 1px solid #d9d9d9;
        border-bottom: 0;
        border-top: 0;
        max-height: 0;
        overflow: hidden;
        &.animation {
          transition: 0.5s ease-out !important;
        }
        .loading-text {
          font-size: 14px;
        }
        &.open {
          border-bottom: 1px solid #d9d9d9;
          max-height: 6000px;
        }
        .row {
          transition: 0.2s ease-out;
          &.comments {
            .title {
              white-space: nowrap;
              align-items: flex-start;
            }
          }
          .col {
            width: 33.33% !important;
            padding: 0 25px !important;
          }
          &.notification {
            padding: 0;
            .col {
              width: 100% !important;
              padding: 0 !important;
            }
          }
        }

        .info-box {
          width: 100% !important;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 10px;
          .product-input-value {
            width: 100%;
            height: 30px;
            border-radius: 8px;
            border: 1px solid $border-grey;
            padding: 8px 13px;
            outline: none;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: 400;
          }
          img {
            max-width: 300px;
          }
          .main-cloned-banner {
            width: 65%;
          }
          span {
            // display: block;
            font-family: 'Open Sans', sans-serif;
            color: $black;
            font-size: 12px;

            &.name {
              width: 100%;
              text-align: left;
              font-weight: 600;
              text-transform: capitalize;
              margin-bottom: 5px;
            }

            &.value {
              // word-wrap: break-word;
              width: 100%;
              text-align: left;
              min-height: 30px;
              border-radius: 8px;
              border: 1px solid $border-grey;
              padding: 5px 10px;
              // display: flex;
              // align-items: center;
              overflow: auto;

              ol,
              ul {
                padding-left: 15px !important;
              }
              p {
                span {
                  display: inline;
                  margin: 0;
                }
                a {
                  all: inherit !important;
                }
                img {
                  object-fit: contain;
                  max-height: 150px;
                  max-width: 300px;
                }
              }
              .reformat-sizes {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                .list {
                  margin-right: 15px;
                  padding: 0 !important;
                  list-style: none;
                  margin-bottom: 15px;
                  width: 130px;
                }
                .list-header {
                  font-weight: 700;
                }
              }
            }
          }
          .show-all-popup {
            font-style: italic;
            margin-left: 10px;
            background-color: transparent;
            border: 0;
          }

          a {
            text-decoration: none;
            span {
              font-weight: 300 !important;
            }
          }

          .download-products {
            display: block;
            width: 15px;
            height: 15px;
            background-color: transparent;
            border: 0;
            cursor: pointer;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-image: url('./../assets/download_bold.svg');
          }
        }
        .image-name {
          display: none;
        }
        .banner-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          .file-content {
            margin-top: 5px;
            margin-right: 40px;
          }
          .add-file-wrapper {
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 30px 20px;
            .file-upload-label {
              display: flex;
              flex-direction: column;
              color: $icon;
              text-align: center;
              font-family: 'Open Sans', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              span {
                font-weight: 400;
              }
            }
          }

          .main-banner {
            // width: 100%;
            max-width: 850px;
            margin-right: auto;
          }
        }
        .banner-action {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          // justify-content: space-between;
          .small-banners {
            position: relative;
            display: flex;
            justify-content: center;
            margin-bottom: 44px;
            .swiper {
              width: 90% !important;
              margin: 0 !important;
            }
            .slide-prev-btn,
            .slide-next-btn {
              position: absolute;
              z-index: 5;
              top: 50%;
              // transform: translateY(-50%);
              background-color: transparent;
              border: 0;
              &::after {
                content: '';
                width: 25px;
                height: 25px;
                display: block;
                cursor: pointer;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                background-image: url('../assets/navigate.svg');
              }
            }
            .slide-prev-btn {
              left: -25px;
              transform-origin: center;
              transform: rotate(-180deg);
              z-index: 2;
            }
            .slide-next-btn {
              right: -25px;
            }

            .slide-prev-btn,
            .slide-next-btn {
              &.disactive {
                pointer-events: none;
                background-color: #e6e6e6;
              }
            }
          }
          img {
            // height: auto;
            max-width: 100%;
            max-height: 175px;
            object-fit: contain;
            &.img-small {
              width: 150px;
              max-width: 100%;
              margin-right: 17px;
            }
          }
          .banner {
            // margin-bottom: 94px;
            width: 100%;
            &.inProgress {
              margin-bottom: 17px;
            }
            .files-header {
              font-size: 14px;
              font-family: 'Open Sans', sans-serif;
              font-weight: 600;
              margin-bottom: 3px !important;
            }
          }
          .links-view {
            width: 100%;
            &.external-links {
              margin-bottom: 20px;
            }
            .text {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              h4,
              span {
                color: $black;
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                font-weight: 700;
              }
              span {
                position: relative;
                font-weight: 300;
                margin-left: 5px;
              }
            }
            .links {
              display: flex;
              align-items: center;
              cursor: pointer;
              &::before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                background-image: url('../assets/link.svg');
                margin-right: 10px;
              }
              span {
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 300;
                font-size: 10px;
                color: $black;
              }
              &:hover {
                span {
                  text-decoration: underline;
                }
              }
            }
            &.archive {
              .text {
                margin-bottom: 5px;
                span {
                  font-style: italic;
                }
              }
              .input-fields {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .error {
                  font-size: 10px;
                  margin: auto;
                }
              }
              .input-box {
                margin-bottom: 6px;
                width: 100%;

                span {
                  font-family: 'Open Sans', sans-serif;
                  font-style: normal;
                  font-weight: 300;
                  font-size: 10px;
                  color: $black;
                  margin-right: 6px;
                  display: inline-block;
                  width: 60px;
                }
                input {
                  width: 80%;
                  max-width: 100%;
                  background: #f0f0f0;
                  border-radius: 5px;
                  margin-right: 6px;
                  border: 0;
                  padding: 5px;
                  font-size: 10px;
                  font-family: 'Open Sans', sans-serif;
                  font-weight: 300;
                  color: $black;
                  &::placeholder {
                    color: rgba(0, 0, 0, 0.3);
                  }
                  &.not-validated {
                    border: 1px solid $danger;
                  }
                }
                button {
                  background: #f0f0f0;
                  border: 1px solid rgba(135, 193, 108, 0.3);
                  border-radius: 5px;
                  width: 76px;
                  max-width: 100%;
                  transition: 0.2s ease-out;
                  &:hover {
                    background-color: $main-green;
                  }
                }
              }
            }
          }
          .swiper-slide {
            width: 150px;
            img {
              max-height: 50px;
              object-fit: contain;
              display: block;
              margin: auto;
            }
          }
        }
      }
    }
  }
  .pagination {
    padding: 9px 20px;
    display: flex;
    justify-content: flex-end;
    button {
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      font-size: 10px;
      padding: 5px;
      background-color: transparent;
      border: 1px solid #d9d9d9;
      border-right: 0;
      height: 30px;
      &:last-child {
        border-right: 1px solid #d9d9d9;
      }
      &.number {
        width: 30px;
      }
      &.prev {
        border-radius: 5px 0 0 5px;
        transform: unset;
      }
      &.next {
        border-radius: 0 5px 5px 0;
        transform: unset;
      }
    }
  }
  .is_favourite {
    color: #03bf06 !important;
  }
  .cancelled {
    color: #bf0603 !important;
  }
  .pin {
    color: $main-green !important;
  }
  .page_component_wrapper {
    height: auto;
    margin-top: 0;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    background: transparent !important;
    .hider,
    .version {
      position: static !important;
    }
    .version {
      margin-right: 10px;
      margin-left: 43px;
    }
    .top_border,
    .bookmark-wrapper {
      position: static;
      margin-top: 0;
    }
    .bookmark-wrapper {
      margin-top: 0;
      order: 1;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px !important;
      .variants-container {
        display: flex;
        align-items: center;
        gap: 15px;
        .page_bookmark {
          margin-right: 0 !important;
        }
      }
      .version {
        margin-right: 20px;
      }
    }
    .top_border {
      order: 2;
    }
    .content {
      order: 3;
    }
  }
  .loading {
    padding: 9px 20px;
  }

  .icons {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    div {
      margin-right: 5px !important;
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
  .assigne-project,
  .reformat-project,
  .mark-as-read,
  .mark-all-as-read,
  .duplicated-project,
  .is_counter,
  .observed,
  .is_restored {
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../assets/Layer_1.svg');
  }

  .reformat-project {
    background-image: url('../assets/przeformatowania.svg');
  }

  .duplicated-project {
    background-image: url('../assets/copy.svg');
  }

  .observed {
    background-image: url('../assets/read.svg');
    filter: brightness(0) saturate(100%);
  }
  .is_counter {
    background-image: url('../assets/time-forward.svg');
    filter: brightness(0) saturate(100%);
  }

  .is_restored {
    background-image: url('../assets/arrow-alt-square-left.svg');
  }

  .mark-as-read {
    width: 14px;
    height: 14px;
    &.unread {
      background-image: url('../assets/unread.svg');
    }
    &.read {
      background-image: url('../assets/read.svg');
    }
  }

  .mark-all-as-read {
    cursor: pointer;
    border: 0 !important;
    filter: invert(0%) sepia(93%) saturate(0%) hue-rotate(235deg) brightness(50%) contrast(107%);
    background-image: url('../assets/read.svg');
    margin-left: auto;
    margin-right: 10px;
  }

  .notification-row {
    padding: 0 !important;
    .td {
      position: relative;
      border: 0 !important;
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.3) !important;
      &:nth-child(1) {
        width: 20%;
        border-right: 0;
      }
      &:nth-child(2) {
        width: 15%;
        border-right: 0;
      }
      &:nth-child(3) {
        width: 15%;
        border-right: 0;
      }
      &:nth-child(4) {
        width: 15%;
        border-right: 0;
      }
      &:nth-child(5) {
        width: 20%;
      }
      &:nth-child(6) {
        span {
          color: rgba(0, 0, 0, 0.3) !important;
        }
        width: 20%;
      }
    }
  }

  .full-list-popup {
    background-color: transparent;
    border: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: $black;
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .table-context {
    .table {
      .tbody {
        .collapse {
          .info-box {
            padding-left: 0;
          }
        }
      }
    }
  }
}
