.filter-bar {
  position: relative;
  width: auto;
  margin-left: auto;
  border-left: 0;
  .filter-bar-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 21px;
    border-radius: 5px;
    padding-right: 6px;
  }
  #label {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 0;
    color: #676a6c;
    margin-right: 5px;
  }

  .dropdown-toggle {
    font-size: 13px !important;
    margin-right: 2px;
    position: relative;
    height: 32px;
    padding: 0 !important;
    display: flex !important;
    align-items: center;
    cursor: pointer;
    span {
      padding: 5px 20px 5px 10px;
      margin-bottom: 0 !important;
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      margin-left: 0.4em;
      margin-right: 0.4em;
      vertical-align: 2px;
      content: '';
      border-top: 0.25em solid;
      border-right: 0.25em solid transparent;
      border-bottom: 0;
      border-left: 0.25em solid transparent;
    }
  }
  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 25%;
    &:hover,
    &.active {
      span {
        color: $black;
        font-weight: 700;
      }
    }

    &.active {
      span {
        color: $black;
        font-weight: 700;
      }
    }
  }

  .dropdown {
    position: static;
  }

  .list-search {
    span {
      width: 100%;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      font-size: 10px;
      margin-bottom: 0;
      height: 22px;
      border: 0;
      padding: 10px 15px;
    }
  }

  li {
    align-items: center;
  }

  .filter-deadline {
    padding: 10px 10px 0 10px;
    border-radius: 5px;
    background: #ffffff;
    border: 1px solid #e4e5e6;
    position: absolute;
    bottom: -173px;
    left: 0;
    z-index: 10;
    .title-section {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      svg {
        margin-right: 15px;
      }
      h3 {
        margin: 0 !important;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.1;
      }
    }
    .calender-input {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      .calender-from,
      .calender-to {
        font-size: 12px;
        padding: 5px 0 5px 10px;
      }
    }
    .btn-search {
      margin-bottom: 10px;
      outline: none;
      padding: 5px 10px;
      height: 30px;
      border-radius: 50px;
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      background-color: #fff;
      color: #303a46;
      border: 1px solid #303a46;
      &:hover {
        background: #303a46 !important;
        color: white !important;
      }
      &:active {
        background-color: #455262 !important;
        color: white !important;
      }
    }
  }

  .calendar-deadline {
    margin-left: 10px;
  }
  .calender-data {
    width: 334px;
    border-radius: 5px;
    border: 0.5px solid #f2f2f2;
    padding: 3px 10px !important;
  }

  .actions {
    margin-top: 20px;
    margin-bottom: 20px;
    button {
      width: 164px;
      color: #fff;
      text-align: center;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
      background: #fff;
      &.accept {
        background: $main-green;
      }
      &.cancel {
        border: 1px solid #ffd600;
        color: #ffd600;
      }
    }
  }
}
