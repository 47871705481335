.abort-popup,
.save-popup,
.send-popup,
.fill-popup,
.take-over,
.duplicate-popup,
.start-observe-popup,
.stop-observe-popup,
.wrong-popup,
.assignee-popup,
.timer-popup,
.new-order-popup,
.counter-popup,
.format-popup,
.order-undergoing-changes-popup,
.restore-popup,
.delete-all-popup,
.error-loading-popup,
.accept-project-popup {
  width: 280px;
  height: 295px;
  border: 1px solid #e4e5e6;
  border-radius: 15px;
  -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 43px 35px 30px 35px;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  & > svg {
    height: 50px;
    width: 50px;
  }
  .upper-part-popup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    svg {
      height: 50px;
      width: 50px;
    }
    a {
      color: $icon;
      &:visited {
        color: $icon;
      }
    }
  }
  .middle-part-popup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    // align-items: center;
    width: 100%;
    margin-top: 30px;
    .input-group {
      width: 100%;
      div {
        width: 100%;
        max-width: 265px;
      }
    }
    .order-name {
      width: 100%;
      height: 30px;
      border-radius: 8px;
      border: 1px solid $border-grey;
      padding: 0 15px;
      color: $black;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 15px;
      &::-webkit-input-placeholder {
        color: #989898;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
      }
      &::-moz-placeholder {
        color: #989898;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
      }
      &:-ms-input-placeholder {
        color: #989898;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
      }
      &::-ms-input-placeholder {
        color: #989898;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
      }
      &::placeholder {
        color: #989898;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
      }
    }
    .correction {
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: $icon;
      strong {
        font-weight: 700;
      }
    }
    .wrapper {
      left: 0px;
      min-width: 0;
      width: 240px;
    }
    .arrow-down {
      top: 45%;
    }
  }
  h3,
  h4 {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #303a46;
    line-height: normal;
    text-align: center;
    margin-top: 25px !important;
    margin-bottom: 0 !important;
  }
  h4 {
    margin-top: 10px !important;
  }
  p {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #303a46;
    margin-top: 10px !important;
  }
  .buttons,
  .single-button {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 30px !important;
    .button {
      border: none;
      outline: none;
      width: 90px;
      height: 35px;
      border-radius: 50px;
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      &.cancel {
        background-color: #fff;
        color: #212529;
        border: 1px solid #e7e7e7;
        &:hover {
          background: #e7e7e7;
        }
        &:active {
          background: #dddddd;
        }
      }
      &.accept {
        background-color: $main-green;
        color: #ffffff;
        &:hover {
          background-color: $main-green-hover;
        }
      }
      &.abort {
        background-color: $danger;
        color: white;
        &:hover {
          background-color: $danger-hover;
        }
      }
    }

    &.centered-buttons {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      .button {
        margin-right: 17px;
      }
    }
  }
  .single-button {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.counter-popup {
  padding: 43px 35px 30px 35px;
  .wrapper {
    width: 254px !important;
  }
  .middle-part-popup {
    margin-top: 25px;
    span {
      width: 100%;
    }
  }
}
.counter-popup,
.duplicate-popup,
.assignee-popup,
.format-popup {
  width: 320px;
  height: 355px;
}
.order-undergoing-changes-popup {
  height: 313px;
}
.restore-popup {
  width: 320px;
  height: 295px;
}
.assignee-popup,
.counter-popup,
.format-popup,
.restore-popup,
.background-src-popup {
  .buttons {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 17px;
  }
}
.save-popup {
  .upper-part-popup {
    width: 60%;
  }
}
.duplicate-popup {
  .upper-part-popup {
    h3 {
      width: 70%;
    }
  }
  .buttons {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 17px;
  }
}
.wrong-popup {
  h3 {
    width: 70%;
  }
  p {
    margin-top: 28px;
  }
}
.info-popup,
// .new-order-popup,
// .timer-popup,
.delete-file-popup {
  z-index: 10;
  position: fixed;
  width: 515px;
  height: 590px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  &.positive {
    border: 8px solid rgba(85, 166, 48, 0.5);
  }
  &.neutral {
    border: 8px solid rgba(255, 205, 77, 0.5);
  }
  &.negative {
    border: 8px solid rgba(210, 81, 79, 0.5);
  }
  &.delete,
  &.remove_variant {
    border: 8px solid rgba(191, 6, 3, 1);
  }
  &.remove {
    border: 8px solid rgba(210, 81, 79, 0.5);
  }
  &.continue {
    border: 8px solid #fff;
  }
  .popup-contents {
    width: 507px;
    height: 575px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 85px;
    &.delete,
    &.remove_variant {
      background: rgba(0, 0, 0, 0.6);
    }
    &.continue {
      background: rgba(0, 0, 0, 0.6);
    }
    .popup-upper-part {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .text-small {
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        color: rgba(94, 94, 94, 1);
        margin-bottom: 6px;
        &.delete,
        &.remove_variant {
          color: rgba(255, 255, 255, 1);
        }
        &.continue {
          color: #fff;
        }
      }
      .text-big {
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-weight: 900;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        &.positive {
          color: rgba(85, 166, 48, 1);
        }
        &.neutral {
          color: rgba(255, 184, 0, 1);
        }
        &.negative {
          color: rgba(191, 6, 3, 1);
        }
        &.delete,
        &.remove_variant {
          color: #fff;
        }
        &.remove {
          color: rgba(191, 6, 3, 1);
        }
        &.continue {
          color: #fff;
        }
      }
    }
    .popup-center-part {
      width: 337px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      .icon-container {
        padding: 0 9px;
        height: 25px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #fff;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      .input-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        margin-bottom: 5px;
        border-radius: 5px;
      }
      .input {
        width: 100%;
        padding: 3px 9.5px 5.5px 0px;
        background: #ffffff;
        height: 25px;
        border: none;
        border-radius: 5px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        line-height: 14px;
        &::-webkit-input-placeholder {
          font-size: 10px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          color: #7a7a7a;
          text-transform: uppercase;
        }
        &::-moz-placeholder {
          font-size: 10px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          color: #7a7a7a;
          text-transform: uppercase;
        }
        &:-ms-input-placeholder {
          font-size: 10px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          color: #7a7a7a;
          text-transform: uppercase;
        }
        &::-ms-input-placeholder {
          font-size: 10px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          color: #7a7a7a;
          text-transform: uppercase;
        }
        &::placeholder {
          font-size: 10px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          color: #7a7a7a;
          text-transform: uppercase;
        }
      }
      span {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
        &.input-suffix {
          padding: 0 8px;
          text-transform: none;
          color: rgba(0, 0, 0, 0.3);
          font-style: italic;
          font-weight: 400;
        }
      }
    }
    .text-center {
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 12px;
      color: rgba(172, 172, 172, 1);
      strong {
        font-weight: 700;
        font-size: 20px;
        color: rgba(94, 94, 94, 1);
      }
      &.negative {
        color: rgba(191, 6, 3, 1);
      }
      &.positive {
        color: rgba(85, 166, 48, 1);
      }
    }
    .timer-section {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      .text-timer {
        text-align: center;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-weight: 400;
      }
      .timer {
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
      }
    }
    .popup-lower-part {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      .popup-buttons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        gap: 7px;
        .popup-button {
          outline: none;
          border: none;
          border-radius: 4px;
          padding: 5px 12px;
          -webkit-box-shadow: none;
          box-shadow: none;
          font-family: 'Montserrat', sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0;
          color: rgba(255, 255, 255, 1);
          &.only {
            text-transform: uppercase;
            &.positive {
              background-color: rgba(85, 166, 48, 1);
            }
            &.neutral {
              background-color: rgba(255, 184, 0, 1);
            }
            &.negative {
              background-color: rgba(85, 166, 48, 1);
            }
          }
          &.first {
            &.positive {
              background-color: rgba(85, 166, 48, 1);
            }
            &.neutral {
              background-color: rgba(85, 166, 48, 1);
            }
            &.negative {
              background-color: rgba(85, 166, 48, 1);
            }
            &.delete,
            &.remove_variant {
              background-color: rgba(191, 6, 3, 1);
            }
            &.remove {
              background-color: rgba(191, 6, 3, 1);
            }
          }
          &.second {
            &.positive {
              background-color: rgba(255, 184, 0, 1);
            }
            &.neutral {
              background-color: rgba(255, 184, 0, 1);
            }
            &.negative {
              background-color: rgba(191, 6, 3, 1);
            }
            &.delete,
            &.remove_variant {
              background-color: rgba(85, 166, 48, 1);
            }
            &.remove {
              background-color: rgba(85, 166, 48, 1);
            }
          }
        }
      }

      .text-last-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 40px;
      }
      .text-last {
        font-family: 'Montserrat', sans-serif;
        font-size: 9px;
        font-weight: 300;
        margin-bottom: 8px;
        line-height: 9px;
        letter-spacing: 0.03em;
        text-align: center;
        color: $black;
        strong {
          font-weight: 600;
        }
        &.delete,
        &.remove_variant {
          color: rgba(255, 255, 255, 1);
        }
        &.continue {
          color: #fff;
        }
      }
    }
  }

  .with-suffix {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.svg-popup,
.video-popup,
.carousel-popup {
  position: fixed !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 11;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  &.carousel {
    width: 100%;
    max-width: 1200px;
    max-height: 700px !important;

    .slide-prev-btn,
    .slide-next-btn {
      &::after {
        width: 35px !important;
        height: 35px !important;
      }
    }

    .slide-prev-btn {
      margin-left: -50px !important;
    }

    .slide-next-btn {
      margin-right: -50px !important;
    }
  }
  img {
    max-width: 1200px !important;
    max-height: 613px !important;
    -o-object-fit: contain;
    object-fit: contain;
  }
  .close-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .title-container {
    background: #fff;
    text-align: center;
    width: 100%;
    padding: 5px 10px;
    margin-top: 10px;
    border-radius: 10px;
  }
}

.delete-file-popup {
  overflow: hidden;
  .popup-contents {
    height: 580px;
  }
}

.accept-project-popup {
  width: 1100px;
  height: 630px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  .bookmark-wrapper {
    margin-bottom: 15px;
  }
  .variants-container {
    margin: auto;
  }
  .content {
    height: 308px !important;
    overflow-y: auto;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #e4e5e6;
    border-radius: 10px;
    padding: 11px;
  }
  .buttons {
    margin-top: 15px !important;
  }
  .input-box {
    min-width: 33.333%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .custom-radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin-bottom: 5px;
    margin-right: 5px;
    font-family: 'Open Sans', sans-serif;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: $black;
    text-transform: capitalize;
    &.language-radio {
      margin-right: 20px;
      .custom-radio-checkmark {
        margin-right: 5px !important;
      }
    }
  }
  img {
    max-height: 112px;
  }
  .custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .custom-radio .custom-radio-checkmark {
    position: relative;
    min-width: 15px;
    min-height: 15px;
    max-height: 15px;
    max-width: 15px;
    width: 15px;
    height: 15px;
    border: 2px solid #b1b2b2; /* Green border */
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 0 !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition:
      background-color 0.3s,
      border-color 0.3s;
    -o-transition:
      background-color 0.3s,
      border-color 0.3s;
    transition:
      background-color 0.3s,
      border-color 0.3s;
  }

  .custom-radio input:checked + .custom-radio-checkmark {
    background-color: $main-green; /* Green background when checked */
    border-color: $main-green;
  }

  .custom-radio input:checked + .custom-radio-checkmark::after {
    display: block;
  }
}
.order-history {
  width: 600px;
  height: 800px;
  border: 1px solid #e4e5e6;
  border-radius: 15px;
  -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 43px 35px 30px 35px;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  overflow: auto;
  h3,
  h4 {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #303a46;
    line-height: normal;
    text-align: center;
    margin-top: 25px !important;
  }
  h4 {
    margin-top: 10px !important;
  }
  p {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #303a46;
    margin-top: 10px !important;
  }
  .history-contents {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    .history-element {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 10px;
      span {
        margin-left: 15px;
        &:first-child {
          margin-left: 0 !important;
        }
      }
    }
  }

  pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 1.428571429;
    word-break: break-all;
    word-wrap: break-word;
    color: #333333;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow-y: auto;
  }
}
