.order-box-action-popup {
  width: 507px;
  max-width: 100%;
  height: 573px;
  &.positive {
    a {
      font-size: 20px;
      font-family: 'Montserrat';
      font-weight: 400;
      color: $black;
      text-decoration: none;
      text-transform: lowercase;
    }
  }

  .popup {
    &__actions {
      max-width: 250px;
      margin: auto auto 0 auto;
    }
    &__text {
      margin: 0;
      &:first-child {
        color: #d9d9d9;
        font-size: 20px !important;
      }
    }

    &__button {
      font-size: 12px;
    }
  }
}
