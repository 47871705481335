.language {
  position: relative;
  .input-group {
    display: flex;
    margin-bottom: 5px;
    .checkbox {
      margin-right: 8px;
    }
  }
  .title-section {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    svg {
      margin-right: 15px;
    }

    h3 {
      margin: 0;
    }
  }

  .error {
    margin: 0;
    font-size: 10px;
  }
}
