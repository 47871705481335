.reformatting-page {
  padding: 17px 9px 145px 9px !important;
  .section-header-wrapper {
    display: flex;
  }
  .wrapper {
    // margin-top: 12px;
    padding: 0 !important;
  }

  .boxes {
    display: flex;
    margin-bottom: 12px;
    flex-wrap: wrap;
  }
  .source-file {
    margin-top: 12px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #e4e5e6;
    min-width: 544px;
    width: calc(33.33% - 9px);
    height: 360px;
    padding: 13px 22px;
    margin-right: 12px;
    &.not-validated {
      border: 1px solid $danger;
    }
  }
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    h3 {
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-size: 12px;
      margin: 0;
      margin-right: 10px;
    }
  }
  .custom-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin-bottom: 5px;
    margin-right: 5px;
    font-family: 'Open Sans', sans-serif;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: $black;
    text-transform: capitalize;
    &.language-radio {
      margin-right: 20px;
      .custom-radio-checkmark {
        margin-right: 5px !important;
      }
    }
  }

  .custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .custom-radio .custom-radio-checkmark {
    position: relative;
    width: 15px;
    height: 15px;
    border: 2px solid #e4e5e6; /* Green border */
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 0 !important;
    box-sizing: border-box;
    transition:
      background-color 0.3s,
      border-color 0.3s;
  }

  .custom-radio input:checked + .custom-radio-checkmark {
    background-color: $main-green; /* Green background when checked */
    border-color: $main-green;
  }

  .custom-radio .custom-radio-checkmark::after {
    content: '';
    position: absolute;
    display: none;
    top: 45%;
    left: 50%;
    width: 2px;
    height: 5px;
    border: solid white;
    border-width: 0 2.5px 2.5px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .custom-radio input:checked + .custom-radio-checkmark::after {
    display: block;
  }
  svg {
    margin-right: 15px;
  }
  .input-group {
    margin-bottom: 7px;
    position: relative;
    min-width: 500px;
    .text-input {
      background: #fff;
      border: 1px solid #e2e2e2;
      height: 30px;
      border-radius: 8px;
      width: 100%;
      padding: 7px 10px;
      max-height: 35px;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      cursor: pointer;
    }
    .presentation {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 100%;
      cursor: pointer;
      .arrow-down {
        top: 44% !important;
        border-color: #989898 transparent transparent !important;
        border-style: solid !important;
        border-width: 6px 4px 0 !important;
        width: 0 !important;
        height: 0 !important;
        left: 30% !important;
        position: absolute !important;
        cursor: pointer;
      }
    }
  }
  .dropdown-area {
    .arrow-down {
      top: 44% !important;
      border-color: #989898 transparent transparent !important;
      border-style: solid !important;
      border-width: 6px 4px 0 !important;
      width: 0 !important;
      height: 0 !important;
      left: 30% !important;
      position: absolute !important;
    }
  }
  .calendar-deadline {
    .arrow-down {
      top: 44% !important;
      border-color: #989898 transparent transparent !important;
      border-style: solid !important;
      border-width: 6px 4px 0 !important;
      width: 0 !important;
      height: 0 !important;
      left: 94% !important;
      position: absolute !important;
    }
  }
  .banner-view {
    min-width: 500px;
    height: 230px;
    padding: 4px;
    background-color: #4d4d4d;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 10px;
    }
  }
  .sizes {
    width: 68%;
    margin-right: 12px;
  }
  .custom-sizes {
    width: 33%;
  }
  .format-list,
  .custom-size-inputs {
    height: 421px;
    overflow-y: auto;
  }

  .formats {
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #e4e5e6;
    max-width: 100%;
    padding: 15px 20px;
    min-height: 560px;
    .row {
      display: flex;
      justify-content: space-between;
    }
    &.not-validated {
      border: 1px solid $danger;
    }
    .format-list {
      column-count: 6;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      max-height: 421px;
      li {
        list-style: none;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        margin-bottom: 19px;
      }

      .group-name {
        display: flex;
        align-items: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-weight: 300;
        color: $black;
        margin-top: 0 !important;
        font-weight: 600;
        cursor: pointer;
        white-space: break-spaces;
        margin: 0 !important;
      }
    }
    .custom-input-sizes {
      position: relative;
      width: 100%;
      height: 100%;
      max-height: 460px;
      min-height: 360px;
      background: #ffffff;
      border: 1px solid #c6c6c6;
      border-radius: 5px;
      padding: 20px;
      .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: auto;
        display: flex;
        flex-basis: content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 213px;
        span {
          white-space: nowrap;
        }
      }
    }
  }
  .reformatting-action-name {
    position: relative;
    display: flex;
    flex-direction: column;
    .error {
      position: absolute;
      left: 0;
      bottom: -15px;
      font-size: 8px;
    }
  }
}
