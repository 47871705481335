@keyframes visible {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.add-new-order {
  position: relative;
  padding: 17px 9px 115px 9px;
  .form-section {
    .form-tabs {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 30px 0px 5px 0px !important;
      h3 {
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        color: #fff;
        font-weight: 700;
        padding: 7px 0;
        margin-right: 13px;
      }
      .tab {
        position: relative;
        width: 112px;
        height: 30px;
        border-radius: 50px;
        background-color: $variant-blue;
        border: 1px solid $variant-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-family: 'Open Sans', sans-serif;
        font-size: 13px;
        color: white;
        font-weight: 400;
        margin-right: 15px;
        margin-bottom: 10px;
        span {
          font-size: 10px;
          display: block;
          text-align: center;
        }
        .remove {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 8px;
          height: 8px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          background-image: url('../assets/e-com-Frame.svg');
          background-color: transparent;
          border: 0;
        }
        &.disactive {
          background-color: white;
          border: 1px solid $variant-blue;
          color: $variant-blue;
          &:hover {
            background-color: $variant-blue-hover;
            color: white;
            border: 1px solid $variant-blue-hover;
          }
          &:active {
            background-color: $variant-blue;
            color: white;
            border: 1px solid $variant-blue;
          }
          .remove {
            display: none;
          }
        }
        &.not-validated {
          border: 1px solid $danger;
        }
        #tooltip {
          position: absolute;
          top: -33px !important;
          left: calc(50% - 40px);
          width: 80px;
          height: 27px;
          background-color: #4d4d4d;
          border-radius: 50px;
          color: white;
          padding: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0; /* Set initial opacity to 0 */
          -webkit-transition: opacity 400ms ease-in-out;
          -moz-transition: opacity 400ms ease-in-out;
          -o-transition: opacity 400ms ease-in-out;
          transition: opacity 400ms ease-in-out; /* Transition for the opacity change */
          animation: visible 0.4s ease-in-out;
          pointer-events: none; /* Avoids mouse events when tooltip is invisible */
          &.tooltipVisible {
            opacity: 1; /* Full opacity when tooltip is supposed to be visible */
            pointer-events: all;
          }
          span {
            font-size: 10px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            width: 100%;
            height: 100%;
            text-align: center;
            vertical-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              background-color: #626262;
              border-radius: 50px;
            }
          }
          #triangle {
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #4d4d4d;
          }
        }
      }
      .add-new-form {
        width: 50px;
        height: 35px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        border: none !important;
        color: #212529;
        border-radius: 50px;
        font-size: 20px;
        background-color: white;
        &:hover {
          background-color: $dark-white;
        }
      }
    }
    .form-tab-content {
      position: relative;
      z-index: 2;
      background-color: #f4f4f4;
    }
  }
  .box-content {
    &.inactive {
      display: none !important;
    }
  }
}

@media (max-width: 1300px) {
  .add-new-order {
    .form-section {
      .form-tabs {
        padding-left: 30px;
      }
    }
  }
}
.Toastify__toast-container--bottom-center {
  left: 50% !important;
  bottom: 8% !important;
  transform: translateX(-50%);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: $warning !important;
  font-family: 'Open Sans', sans-serif !important;
  color: #fff !important;
}
