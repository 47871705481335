.top-inputs {
  width: 100%;
  height: 90px;
  border-radius: 8px;
  border: 1px solid #e4e5e6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 20px 30px;
  .input-box {
    display: flex;
    align-items: center;
    width: 33.33%;
    div {
      width: 100%;
      max-width: 265px;
    }
    h3 {
      color: $black;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-weight: 700;
      margin: 0 10px 0 0 !important;
    }
    input {
      background: #ffffff;
      font-family: 'Open Sans', sans-serif;
      border-radius: 8px;
      width: 100%;
      height: 30px;
      max-width: 265px;
      padding: 6px 10px;
      border: 0.7px solid #e2e2e2;
      &::placeholder {
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-weight: 400;
        color: #989898;
      }
      &:focus {
        border: 0.7px solid $main-green;
      }
      &.blocked {
        pointer-events: none;
      }
    }
    &.order-1 {
      order: 1;
    }
    &.order-2 {
      order: 2;
    }
    &.order-3 {
      order: 3;
    }
    .error {
      // display: none;
      position: absolute;
      left: 0;
      bottom: -15px;
      font-size: 10px;
    }
    &.not-validated {
      .error {
        display: block;
      }
      .calender-data {
        border: 1px solid $danger !important;
      }
      input {
        border: 1px solid $danger;
        border-radius: 10px;
      }
    }

    .actions {
      .error {
        position: static;
      }
    }
  }
}

@media (max-width: 1900px) {
  .top-inputs {
    .input-box {
      margin-bottom: 8px;
    }
  }
}
