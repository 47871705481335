.custom-size-input {
  width: 80px;
  height: 23px !important;
  padding: 3px 10px;
  border-radius: 8px;
  border: 1px solid #e4e5e6;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  &:focus {
    border: 1px solid $main-green;
  }
}
.custom-sizes {
  label {
    list-style: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    margin-bottom: 19px;
  }
  li {
    list-style: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    margin-bottom: 19px;
  }
}

.list-element,
.custom-size-element {
  &.add-element {
    display: flex;
    align-items: center;
    span {
      margin: 0;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      &.custom-text {
        margin-right: 5px;
      }
    }
    .add-input {
      position: relative;
    }
    button {
      position: absolute;
      top: 52%;
      right: 16px;
      transform: translateY(-50%);
      background-color: transparent;
      border: 0;
    }
  }
  input {
    margin-right: 10px;
  }
}
.add-new-order {
  .custom-size-element {
    &.add-element {
      button {
        right: 22px;
      }
    }
  }
}
