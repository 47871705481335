.view {
  width: 100%;
  position: relative;
  padding: 18px 21px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e2e2e2;

  .wrapper {
    padding-left: 0;
  }

  .title {
    height: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h2 {
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      color: $black;
      font-weight: 700;
      text-align: left;
      margin: 0 !important;
    }

    svg {
      margin-right: 15px;
    }
  }

  &.inactive {
    pointer-events: none;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      background: #ffffff;
      opacity: 0.8;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 5px;
      z-index: 1;
    }
  }
}

.add-new-product {
  width: 50px;
  height: 30px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  color: #212529;
  border-radius: 50px;
  font-size: 20px;
  background-color: white;
  border: 1px solid #e4e5e6;

  &:hover {
    background-color: $dark-white;
  }
}

.products {
  width: 100%;

  &.team-view {
    // padding: 0;
    margin: 0 !important;
    padding: 0 90px;
    max-width: 800px;
    width: 100%;
    margin: auto;
    position: relative;
    margin-bottom: 24px;
  }

  .slide-prev-btn {
    left: -25px !important;
  }

  .slide-next-btn {
    right: -25px !important;
  }

  .block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 300px;
    height: 342px;
    margin-right: 15px;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 4px 4px 18px 4px;
    overflow: hidden;

    .icons {
      position: absolute;
      bottom: 10px;
      right: 10px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &.add {
      position: relative;

      .add-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        &::after {
          content: '+';
          color: #cadad2;
          font-size: 70px;
          font-weight: 700;
          font-family: 'Open Sans', sans-serif;
        }
      }
    }

    img {
      object-fit: contain;
      width: 100%;
    }
  }

  .wrapper {
    padding: 0 !important;
  }

  .products-wrapper {
    position: relative;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &.team-view {
      box-shadow: unset;
      padding: 0;

      .block {
        margin: 0 !important;
      }
    }

    .photo {
      width: 290px;
      height: 260px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      background-color: #4d4d4d;

      img {
        transform-origin: 50% 50%;
        transform: translate(0%, 0%);
      }

      &.no-photo {
        justify-content: center;
        width: 100%;

        img {
          width: 50%;
          height: 50%;
          object-fit: contain;
        }
      }
    }
  }

  img {
    margin: auto;
    display: block;
    border-radius: 8px;
    position: absolute;
  }

  .icons {
    display: flex;
    justify-content: center;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-right: 5px;
    cursor: pointer;
    border: 1px solid $border-grey;
    border-radius: 8px;
    transition: 0.2s ease-out;

    &.minus {
      margin-right: 15px;
    }

    &:hover {
      background-color: #e4e5e6;
    }
  }

  .product-counter {
    position: relative;
    cursor: pointer;
    width: 50px;
    height: 30px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: white;
    font-weight: 400;
    border-radius: 50px;
    background-color: #23c6c8;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-bottom: 10px;
  }
}
