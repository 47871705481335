.delimiter {
  margin-right: 10px !important;
}

.product-value {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .product-titles {
    margin-bottom: 7px;
    span {
      font-weight: 700;
    }
  }
  a,
  span {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: $black;
  }
  span {
    margin-right: 78px;
    width: 100px !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    &:last-child {
      margin-right: 0;
    }
  }
  .product-reference,
  .product-titles {
    text-decoration: none !important;
    pointer-events: all !important;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    span {
      width: 33.33% !important;
      margin-right: 0 !important;
    }
    &.no-photo {
      pointer-events: none !important;
      .value {
        color: #ce5a69 !important;
      }
    }
  }
  .product-reference {
    justify-content: space-between;
    margin-bottom: 5px;
    .value-ean,
    .size,
    .rotation {
      width: 32% !important;
      border: 1px solid $border-grey;
      border-radius: 8px;
      padding: 0 10px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .download-products {
    text-decoration: none !important;
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('./../assets/download_bold.svg');
    margin: 10px 0;
  }
}
.product-titles {
  text-decoration: none !important;
  pointer-events: all !important;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    width: 32% !important;
    margin-right: 0 !important;
    font-weight: 700 !important;
  }

  &.no-photo {
    pointer-events: none !important;

    .value {
      color: #ce5a69 !important;
    }
  }
}
.product-reference {
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
  text-decoration: none;
  display: flex;
  .value-ean,
  .size,
  .rotation {
    width: 32% !important;
    border: 1px solid $border-grey;
    border-radius: 8px;
    padding: 0 10px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Open Sans', sans-serif;
    color: #1d1d1d;
    font-size: 12px;
  }
}
