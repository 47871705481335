.correction-list-wrapper {
  padding: 15px 25px;
  align-items: start !important;
  .title {
    color: #ff0000;
    text-align: right;
    margin: 0;
    margin-right: 15px;
    font-size: 13px;
    padding: 0;
    justify-content: end;
    align-items: start;
    text-transform: uppercase;
    font-weight: 700;
    padding-top: 5px;
  }
  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 151px;
    line-height: 26px;
    width: 100%;
    max-width: 859px;
    align-content: space-between;
    li {
      list-style: none;
      div {
        font-size: 13px;
        text-decoration: none;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        color: $black;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
