.new-order-box-container {
  .boxes-container {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: space-between;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
