.box-content {
  margin-bottom: 28px;
}
.box-wrapper {
  .row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .column {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    min-width: 544px;
    width: calc(33.33% - 9px);
    &:last-child {
      margin: 0;
    }
  }
}

@media (max-width: 1280px) {
  .box-wrapper {
    .row {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 1100px) {
  .box-wrapper {
    padding-bottom: 150px;
  }
}
