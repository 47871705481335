.order-details {
  padding-top: 50px;
  .row {
    padding: 10px;
    transition: 0.2s ease-out;
    display: flex;
    width: 100%;
    margin: 0;
    &.correction-list-wrapper {
      padding-left: 80px !important;
      .title {
        width: 250px !important;
      }
    }
    .col {
      width: 50% !important;

      .info-box {
        width: 100% !important;
        display: flex;
        margin-bottom: 10px;
        padding-left: 80px;

        .name {
          margin-right: 15px;
          width: 250px;
          text-align: right;
          font-weight: 700;
          text-transform: uppercase;
          font-family: 'Montserrat', sans-serif;
          color: $black;
          font-size: 13px;
        }

        .value {
          width: 65%;
          text-align: left;
          font-family: 'Montserrat', sans-serif;
          color: $black;
          font-size: 13px;
        }
      }
    }
    .info-box {
      width: 100% !important;
      display: flex;
      margin-bottom: 10px;
      padding-left: 80px;
      img {
        max-width: 300px;
      }
      .main-cloned-banner {
        width: 65%;
      }
      span {
        // display: block;
        font-family: 'Montserrat', sans-serif;
        color: $black;
        font-size: 13px;

        &.name {
          margin-right: 15px;
          width: 250px;
          text-align: right;
          font-weight: 700;
          text-transform: uppercase;
        }

        &.value {
          // word-wrap: break-word;
          width: 65%;
          text-align: left;
          ol,
          ul {
            padding-left: 15px !important;
          }
          p {
            span {
              display: inline;
              margin: 0;
            }
            a {
              all: inherit !important;
            }
            img {
              object-fit: contain;
              max-height: 150px;
              max-width: 300px;
            }
          }
          .reformat-sizes {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            .list {
              margin-right: 15px;
              padding: 0 !important;
              list-style: none;
              margin-bottom: 15px;
              width: 130px;
            }
            .list-header {
              font-weight: 700;
            }
          }
        }
      }
      .show-all-popup {
        font-style: italic;
        margin-left: 10px;
        background-color: transparent;
        border: 0;
      }

      a {
        text-decoration: none;
        span {
          font-weight: 300 !important;
        }
      }

      .download-products {
        display: block;
        width: 15px;
        height: 15px;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url('./../assets/download_bold.svg');
      }
    }
    .image-name {
      display: none;
    }
    .banner-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      .file-content {
        margin-right: 40px;
      }
      .add-file-wrapper {
        margin: 0;
        p {
          display: flex;
          flex-direction: column;
          color: $black;
          text-align: center;
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          text-transform: uppercase;
          font-weight: 700;
          span {
            font-weight: 400;
          }
        }
      }

      .main-banner {
        // width: 100%;
        max-width: 850px;
        margin-right: auto;
      }
    }
    .banner-action {
      padding-left: 18px;
      padding-right: 18px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // justify-content: space-between;
      .small-banners {
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 44px;
        .swiper {
          width: 90% !important;
          margin: 0 !important;
        }
        .slide-prev-btn,
        .slide-next-btn {
          position: absolute;
          z-index: 5;
          top: 50%;
          // transform: translateY(-50%);
          background-color: transparent;
          border: 0;
          &::after {
            content: '';
            width: 25px;
            height: 25px;
            display: block;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-image: url('../assets/navigate.svg');
          }
        }
        .slide-prev-btn {
          left: -25px;
          transform-origin: center;
          transform: rotate(-180deg);
          z-index: 2;
        }
        .slide-next-btn {
          right: -25px;
        }

        .slide-prev-btn,
        .slide-next-btn {
          &.disactive {
            pointer-events: none;
            background-color: #e6e6e6;
          }
        }
      }
      img {
        // height: auto;
        max-width: 100%;
        max-height: 175px;
        object-fit: contain;
        &.img-small {
          width: 150px;
          max-width: 100%;
          margin-right: 17px;
        }
      }
      .banner {
        // margin-bottom: 94px;
        width: 100%;
        &.inProgress {
          margin-bottom: 17px;
        }
      }
      .links-view {
        width: 100%;
        margin-top: 26px;
        &.external-links {
          margin-bottom: 20px;
        }
        .text {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          h4,
          span {
            color: $black;
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            font-weight: 700;
          }
          span {
            position: relative;
            font-weight: 300;
            margin-left: 5px;
          }
        }
        .links {
          display: flex;
          align-items: center;
          cursor: pointer;
          &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-image: url('../assets/link.svg');
            margin-right: 10px;
          }
          span {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 10px;
            color: $black;
          }
          &:hover {
            span {
              text-decoration: underline;
            }
          }
        }
        &.archive {
          .text {
            margin-bottom: 5px;
            span {
              font-style: italic;
            }
          }
          .input-fields {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .error {
              font-size: 10px;
              margin: auto;
            }
          }
          .input-box {
            margin-bottom: 6px;
            width: 100%;

            span {
              font-family: 'Montserrat', sans-serif;
              font-style: normal;
              font-weight: 300;
              font-size: 10px;
              color: $black;
              margin-right: 6px;
              display: inline-block;
              width: 60px;
            }
            input {
              width: 80%;
              max-width: 100%;
              background: #f0f0f0;
              border-radius: 5px;
              margin-right: 6px;
              border: 0;
              padding: 5px;
              font-size: 10px;
              font-family: 'Montserrat', sans-serif;
              font-weight: 300;
              color: $black;
              &::placeholder {
                color: $black;
                background: rgba(0, 0, 0, 0.3);
              }
              &.not-validated {
                border: 1px solid $danger;
              }
            }
            button {
              background: #f0f0f0;
              border: 1px solid rgba(135, 193, 108, 0.3);
              border-radius: 5px;
              width: 76px;
              max-width: 100%;
              transition: 0.2s ease-out;
              &:hover {
                background-color: $main-green;
              }
            }
          }
        }
      }
      .swiper-slide {
        width: 150px;
        img {
          max-height: 50px;
          object-fit: contain;
          display: block;
          margin: auto;
        }
      }
    }
  }
  .correction-list-wrapper {
    padding: 14px;
    border-top: 1px solid #f2f2f2;
    align-items: start !important;
    .title {
      color: #ff0000;
      width: 157px;
      text-align: right;
      margin: 0;
      margin-right: 15px;
      font-size: 13px;
      padding: 0;
      justify-content: end;
      align-items: start;
      text-transform: uppercase;
      font-weight: 700;
      padding-top: 5px;
    }
    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 151px;
      line-height: 26px;
      width: 100%;
      max-width: 859px;
      align-content: space-between;
      li {
        list-style: none;
        div {
          font-size: 13px;
          text-decoration: none;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 300;
          color: $black;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .actions {
    margin-top: auto;
    padding: 9px 20px;
    display: flex;
    align-items: center;
    gap: 2px;
    position: relative;
    z-index: 4;
    flex-wrap: wrap;

    .show-number {
      margin-right: auto;
      span,
      input {
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        font-size: 10px;
        // color: #d9d9d9;
      }
      input {
        width: 30px;
        height: 20px;
        margin: 0 5px;
        padding: 5px;
        border: 1px solid #d9d9d9;
      }
    }
    .user_can_not_accept {
      .accept,
      .reject,
      .add-corection {
        pointer-events: none;
        background-color: #e6e6e6;
      }
    }
    .accepted {
      .add-corection,
      .accept {
        pointer-events: none;
        background-color: #e6e6e6;
      }
    }
  }
}
