.image-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 7;
  .image-name {
    margin-top: 5px;
    display: block !important;
    background-color: #fff;
    display: block;
    width: 100%;
    text-align: center;
    padding: 8px;
    border-radius: 8px;
    pointer-events: none;
  }
  figure {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &.carousel {
    width: 100%;
    max-width: 1200px;
    max-height: 700px !important;

    .slide-prev-btn,
    .slide-next-btn {
      &::after {
        width: 35px !important;
        height: 35px !important;
      }
    }

    .slide-prev-btn {
      margin-left: -50px !important;
    }
    .slide-next-btn {
      margin-right: -50px !important;
    }
  }

  img {
    max-width: 1200px !important;
    max-height: 700px !important;
    object-fit: contain;
  }
  .close-button {
    position: absolute;
    top: -40px;
    right: -30px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../../assets/closse-icon.svg');
    z-index: 5;
    cursor: pointer;
  }
}
