/* FONTS */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=News+Cycle:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=News+Cycle:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import './scss/LocalFonts';

//text editor plugin styles
@import '../node_modules/react-quill-new/dist/quill.bubble.css';

@import './scss/ceta-styles';
@import './scss/GlobalStyles';
@import './scss/header';
@import './scss/MainAppComponent';
@import './scss/Menu';
@import './scss/ClientService';
@import './scss/Reformatting';
@import './scss/AddNewOrder';
@import './scss/OrderBoxes';
@import './scss/OrderBoxContent';
@import './scss/CampaingDropdown';
@import './scss/CalendarDeadline';
@import './scss/LoadingPage';
@import './scss/ActionDataButtons';
@import './scss/Pagination';
@import './scss/ProductView';
@import './scss/SearchBar';
@import './scss/TopInputs';
@import './scss/_Loader';
@import './scss/FilterBar';
@import './scss/Order';
@import './scss/OrderBoxFields/Size';
@import './scss/OrderBoxFields/InputText';
@import './scss/OrderBoxFields/RadioList';
@import './scss/OrderBoxFields/Dropdown';
@import './scss/OrderBoxFields/Color';
@import './scss/OrderBoxFields/AddProduct';
@import './scss/OrderBoxFields/RadioButtonTxt';
@import './scss/OrderBoxFields/Apla';
@import './scss/Language';
@import './scss/TableContext.scss';
@import './scss/AcceptProject';
@import './scss/Correction';
@import './scss/TeamBox.scss';
@import './scss/NewOrderBox.scss';
@import './scss/MyProjectBox.scss';
@import './scss/KeyVisual.scss';
@import './scss/ExternalLink';
@import './scss/TableProductList';
@import './scss/Tables/AgentionNotes';
@import './scss/Tables/DownloadDataSheet';
@import './scss/Team.scss';
@import './scss/Raports/RaportsContainer';
@import './scss/CalenderFromTo';
@import './scss/SelectBox';
@import './scss/Tables/AuthorInfo';
@import './scss/TextInputBoxes/TextInputBoxes';
@import './scss/TextInputBoxes/TextBox';
@import './scss/Tables/_OrderEditInfo';
@import './scss/CompareVariants/CompareVariants';
@import './scss/OrderBoxFields/LogoTypeChooser';
@import './scss/CorrectionList';
@import './scss/CustomSizeInputs';
@import './scss/_Specification.scss';
@import './scss/TableLogotypeList';

//POPUPS
@import './scss/Popup/Popup';
@import './scss/Popup/Popups';
@import './scss/Popup/PopupComponents/BoxInfoPopup';
@import './scss/Popup/PopupComponents/AddCounterPopup';
@import './scss/Popup/PopupComponents/ChangeDeadlinePopup';
@import './scss/Popup/PopupComponents/AssignePopup';
@import './scss/Popup/PopupComponents/RemoveImagePopup';
@import './scss/Popup/PopupComponents/TotalRemovePopup';
@import './scss/Popup/PopupComponents/OrderBoxActionPopup';
@import './scss/OrderBoxFields/AddProductPopup';
@import './scss/Popup/ImageModal';
@import './scss/Popup/_BackgroundSrcInputPopup';
@import './scss/Popup/AddLogotypePopup';
@import './scss/Popup/_ObservePopup';

@import './scss/DropdownList';
