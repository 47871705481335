.add-counter-popup {
  justify-content: center;
  align-items: center;
  overflow: unset;
  span {
    margin-bottom: 10px;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    max-width: 334px;
    margin-bottom: 30px;
    input {
      max-height: 28px;
      width: 100%;
      margin-bottom: 0 !important;
    }
  }

  input {
    padding: 5px;
    border: 1px solid $black;
  }

  .input-error {
    box-shadow: 0px 0px 10px #ce5a69;
  }
}
