.download-data-sheet {
  display: flex;
  width: auto;
  max-width: 363px;
  margin-left: 20px;
  border-radius: 5px;
  .download-data-sheet-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    span {
      display: flex;
      align-items: center;
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      font-size: 13px;
      margin-bottom: 0;
      height: 22px;
      color: #676a6c;
      margin-right: 5px;
    }
  }

  .label {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border-right: 0.75px solid #f2f2f2;
    background: #fefcfc;
    padding: 0;
    // margin-right: 30px;
    // width: 57px;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 19px;
      height: 19px;
      border-radius: 5px;
      border-right: 0.75px solid #f2f2f2;
      margin-right: 7px;
      &::after {
        content: '';
        display: block;
        width: 11px;
        height: 11px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url('../../assets/file-export.svg');
      }
    }
    span {
      display: block;
      text-transform: uppercase;
      width: 48px;
    }
  }

  span,
  a {
    //color: #979797;
    //font-family: "Open Sans", sans-serif;
    //font-size: 10px;
    //font-style: normal;
    //font-weight: 300;
    //line-height: normal;
    //margin: 0;
    //transition: 0.2s ease-out;
  }

  a {
    cursor: pointer;
    &:hover {
      color: $black;
      font-weight: 700;
    }
  }
}
