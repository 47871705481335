.logo-chooser {
  width: 100%;
  .chooser-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .swiper {
    z-index: 2 !important;
    overflow: visible !important;
  }
  .text-wrapper {
    span {
      display: inline-block;
      margin-bottom: 5px;
    }
    margin-top: auto;
  }
  .column {
    max-width: 72px !important;
    min-width: 72px !important;
  }
  #tooltip {
    position: absolute;
    bottom: 120%;
    left: calc(50% - 40px);
    width: 80px;
    height: 27px;
    background-color: #4d4d4d;
    border-radius: 50px;
    color: white;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0; /* Set initial opacity to 0 */
    -webkit-transition: opacity 400ms ease-in-out;
    -moz-transition: opacity 400ms ease-in-out;
    -o-transition: opacity 400ms ease-in-out;
    transition: opacity 400ms ease-in-out; /* Transition for the opacity change */
    animation: visible 0.4s ease-in-out;
    pointer-events: none; /* Avoids mouse events when tooltip is invisible */
    &.tooltipVisible {
      opacity: 1; /* Full opacity when tooltip is supposed to be visible */
      pointer-events: all;
    }
    span {
      font-size: 10px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      width: 100%;
      height: 100%;
      text-align: center;
      vertical-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      &:hover {
        background-color: #626262;
        border-radius: 50px;
      }
    }
    #triangle {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #4d4d4d;
    }
  }

  .chooser {
    height: 26px;
    background: #f4f4f4;
    border-radius: 5px;
    transition: 0.2s ease-out;
    border-radius: 3px;
    border: 0;
    cursor: pointer;
    color: $black;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    &:hover {
      background: rgba(76, 129, 103, 0.3);
    }
    &.chosen {
      background: rgba(76, 129, 103, 0.3);
      border: 1.5px solid $main-green;
      color: #ffffff;
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
      font-weight: 700;
    }
  }

  .view {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 94px;
    min-height: unset;
    border-radius: 8px;
    margin-bottom: 3px;
    padding: 10px;
    img {
      max-width: 100%;
      object-fit: contain;
    }
  }

  .view-controler {
    display: flex;
    justify-content: center;
  }

  .icon {
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-right: 5px;
    cursor: pointer;
    &.refresh {
      background-image: url('../../assets/e-com-refresh.svg');
    }
    &.remove {
      background-image: url('../../assets/e-com-trash.svg');
      margin-right: 0;
    }
  }

  .logotype {
    position: relative;
    cursor: pointer;
    width: 50px;
    height: 30px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: white;
    font-weight: 400;
    border-radius: 50px;
    background-color: $variant-blue;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .add-new-product {
    width: 50px;
    height: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #212529;
    border-radius: 50px;
    font-size: 20px;
    background-color: white;
    border: 1px solid #e4e5e6;
    &:hover {
      background-color: $dark-white;
    }
  }

  button {
    margin-right: auto;
  }

  .ql-editor {
    ::-webkit-scrollbar {
      width: 12px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: #d9d9d9;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
  }
}
