.raports-container {
  background: #f4f4f4;
  height: calc(100vh - 50px);
  padding: 17px 9px 115px 9px;
  .raports-value-list {
    margin-right: 60px;
    margin-bottom: 60px;
  }
  .filter-deadline {
    margin-bottom: 60px;
  }
  h3 {
    color: $black;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 !important;
  }
  .title-section {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    svg {
      margin-right: 15px;
    }
    .not-validated {
      color: $danger;
    }
  }
  .raports-settings {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid $border-grey;
  }

  .raports-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    li {
      list-style: none;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    margin-bottom: 30px;
    .block {
      margin-bottom: 30px;
    }
  }

  .generate-raport {
    height: 30px;
    padding: 0 12px;
    display: block;
    margin: auto;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 13px;
    border-radius: 50px;
    cursor: pointer;
    background: white;
    border: 1px solid $button-black;
    color: $button-black;
    line-height: 11px;
    &:hover {
      background: $button-black;
      color: white;
    }
    &:active {
      background: $button-black-active;
    }
  }

  .raport-headers {
    display: flex;
    justify-content: flex-start;
    padding: 30px 20px;
    background-color: #fff;
    flex-wrap: wrap;
    margin-bottom: 12px;
    border-radius: 10px;
    border: 1px solid $border-grey;
    .raport-header {
      display: flex;
      align-items: center;
      width: 400px;
      div {
        width: 100%;
        max-width: 265px;
      }
      h3 {
        color: $black;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-weight: 700;
        margin: 0 21px 0 0 !important;
      }
      input {
        background: #ffffff;
        font-family: 'Open Sans', sans-serif;
        border-radius: 8px;
        width: 100%;
        height: 30px;
        max-width: 267px;
        padding: 6px;
        border: 0.7px solid #e2e2e2;
        &::placeholder {
          font-family: 'Open Sans', sans-serif;
          font-size: 12px;
          font-weight: 700;
          color: #efefef;
        }
        &:focus {
          border: 0.7px solid $main-green;
        }
        &.blocked {
          pointer-events: none;
        }
      }
    }
  }
  .custom-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin-right: 5px;
    font-family: 'Open Sans', sans-serif;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: $black;
    text-transform: capitalize;
    &.language-radio {
      margin-right: 20px;
      .custom-radio-checkmark {
        margin-right: 5px !important;
      }
    }
  }

  .custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .custom-radio .custom-radio-checkmark {
    position: relative;
    width: 15px;
    height: 15px;
    border: 2px solid #e4e5e6; /* Green border */
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 0 !important;
    box-sizing: border-box;
    transition:
      background-color 0.3s,
      border-color 0.3s;
  }

  .custom-radio input:checked + .custom-radio-checkmark {
    background-color: $main-green; /* Green background when checked */
    border-color: $main-green;
  }

  .custom-radio input:checked + .custom-radio-checkmark::after {
    display: block;
  }

  .not-validated {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 10px;
    color: $danger;
  }
}

@media (max-width: 1290px) {
  .raports-container {
    .raports-value-list,
    .filter-deadline {
      min-width: 300px;
      margin-right: 30px;
    }
    .raports-wrapper {
      justify-content: flex-start;
    }
  }
}
