.outer-add-logotype-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 7;
  .add-logotype-popup {
    display: block;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 50px 0px rgba(139, 139, 139, 0.25);
    border-radius: 15px;
    padding: 29px 34px;
    overflow: hidden;
    padding: 46px 35px 35px 35px;
    border: 1px solid $border-grey;
    width: 320px;
    height: 500px;
    .buttons,
    .single-button {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: center;
      gap: 17px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 30px !important;

      .button {
        border: none;
        outline: none;
        width: 90px;
        height: 35px;
        border-radius: 50px;
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;

        &.cancel {
          background-color: #fff;
          color: #212529;
          border: 1px solid #e7e7e7;

          &:hover {
            background: #e7e7e7;
          }

          &:active {
            background: #dddddd;
          }
        }

        &.accept {
          background-color: $main-green;
          color: #ffffff;

          &:hover {
            background-color: $main-green-hover;
          }
        }

        &.abort {
          background-color: $danger;
          color: white;

          &:hover {
            background-color: $danger-hover;
          }
        }
      }
    }
    .title-section {
      margin-bottom: 25px;
      h3 {
        font-family: 'Open Sans', sans-serif;
        text-align: center;
        color: $popup_title;
        font-size: 14px;
        font-weight: 700;
      }
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
      }
    }
    .fields {
      h2 {
        color: $black;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        width: auto;
        text-align: right;
        text-transform: uppercase;
        margin-right: 11px;
        margin-top: 0;
        margin-bottom: 0;
        white-space: nowrap;
      }
      span {
        display: block;
        height: 12px;
      }
      .index {
        position: relative;
        margin-bottom: 15px;
        .input-field {
          width: 100%;
          background: #ffffff;
          border-radius: 8px;
          width: 250px;
          height: 30px;
          border: 1px solid $border-grey;
          padding: 3px 15px;
          font-family: 'Open Sans', sans-serif;
          color: $popup_title;
          font-size: 12px;
        }

        &.error-field {
          input {
            filter: drop-shadow(0px 0px 10px #ce5a69);
          }
        }
      }

      .show-field {
        position: relative;
        background: #ffffff;
        border-radius: 8px;
        width: 250px !important;
        height: 175px !important;
        border: 1px solid $border-grey;
        padding: 3px 15px;
        margin-bottom: 34px;
        &.error {
          filter: drop-shadow(0px 0px 10px #ce5a69);
          margin-left: 0;
          margin: 0;
        }
      }
    }

    .view-error {
      margin-top: -20px;
      color: red;
      font-size: 8px;
      margin-left: 152px;
      display: block;
      margin-bottom: 10px;
    }

    .bottom {
      display: flex;
      justify-content: center;
      gap: 7px;
      button {
        width: 90px;
        height: 35px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 12px;
        color: #ffffff;
        border-radius: 50px;
        border: 0;
        cursor: pointer;
        &.add,
        &.save {
          background: $main-green;
          &:hover {
            background: $main-green-hover;
          }
        }

        &.cancel {
          background: $cancel-button;
          border: 1px solid $border-grey;
          color: #212529;
          margin-right: 17px;
          &:hover {
            background: #e7e7e7;
          }
          &:active {
            background: #dddddd;
          }
        }
      }
    }

    .error {
      font-size: 12px;
      margin-left: 152px;
      display: block;
      margin-bottom: 10px;
    }

    .show-field {
      display: flex;
      flex-direction: column;
      padding: 10px;
      overflow-y: auto;

      label {
        position: relative;
        display: flex;
        align-items: center;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        justify-content: flex-start;
        color: $main-green;

        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 0;
          opacity: 0;
        }

        .checkmark {
          height: 6px !important;
          width: 6px !important;
          background: #ffffff;
          border: 0.5px solid #d9d9d9;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
          border-radius: 50%;
          margin-right: 10px;
        }

        input[type='checkbox']:checked ~ .checkmark {
          background: $main-green;
          border: 0.5px solid #ffffff;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
        }

        figure {
          position: static !important;
          transform: 0;
          width: 90%;
          margin-left: 10px;

          img {
            max-width: 30%;
            display: block;
          }
        }
      }
    }

    .no-photo {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.chooser {
    .add-logotype-popup {
      .fields {
        .show-field {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 200px;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
