.dropdown-box {
  flex-direction: column;
  width: 100%;
  margin-bottom: 0 !important;
  .input-field {
    position: relative;
    .checkbox-li-input {
      display: block;
    }
    input,
    .checkbox-li-input {
      background: #f4f4f4;
      border-radius: 3px;
      width: 465px;
      max-width: 100%;
      padding: 7px 9px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      font-size: 8px;
      color: rgba(0, 0, 0);
      border: 0;
    }
    &::after {
      content: 'v';
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
      color: #ffd600;
      font-weight: 700;
    }
  }
  &.p-l {
    padding-left: 25px;
    .input-field {
      input,
      .checkbox-li-input {
        width: 450px;
      }
    }
  }

  .custom-input {
    position: relative;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #e4e5e6;
    width: 100%;
    height: 30px;
    display: flex;
    padding: 0 10px;
    align-items: center;
    cursor: pointer;
    &.focus {
      border: 1px solid $main-green;
    }
    span {
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      margin-bottom: 0 !important;
      pointer-events: none;
      margin-right: 5px;
      position: relative;
      //padding-left:13px;

      &.info {
        color: $black !important;
        color: #989898 !important;
        font-family: 'Open Sans', sans-serif !important;
        font-weight: 400 !important;
      }
    }
    .arrow-down {
      top: 44%;
      border-color: #989898 transparent transparent;
      border-style: solid;
      border-width: 6px 4px 0;
      width: 0;
      height: 0;
      left: 97%;
      position: absolute;
    }
  }
}
