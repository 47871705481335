.size-chooser {
  margin-bottom: 22px;
  position: relative;
  .size-list-dropdown {
    position: absolute;
    top: calc(100% + 3px);
    left: 0;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #e4e5e6;
    width: 100%;
    z-index: 2;
    padding: 5px !important;
    li {
      border-radius: 5px;
      cursor: pointer;
      margin: 0;
      pointer-events: all;
      &:hover {
        background: #f5f5f5 !important;
      }
    }
    label {
      padding: 10px 15px;
      width: 100%;
      margin: 0;
    }
  }
  .arrow-down {
    top: 44%;
    border-color: #989898 transparent transparent;
    border-style: solid;
    border-width: 6px 4px 0;
    width: 0;
    height: 0;
    left: 97%;
    position: absolute;
  }
  .custom-input {
    position: relative;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #e4e5e6;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 10px;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    color: $black !important;
    &:focus {
      border: 1px solid $main-green;
    }
  }

  .custom-radio-checkmark {
    display: none !important;
  }
}
